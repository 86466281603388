<template>
    <div class="empty-container">
        <img src="@/assets/img/addApply.png" alt="" />
        <div class="text">应用空空如也哦，请联系管理员！</div>
        <!-- <el-button
            type="primary"
            size="small"
            class="btn"
            icon="el-icon-plus"
            @click="() => $router.push('/workCenter/appListCenter/service')"
            >添加应用</el-button
        > -->
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    props: {
        height: Number
    },
    methods: {},
    mounted() {}
};
</script>

<style lang="less" scoped>
.empty-container {
    width: 100%;
    text-align: center;
    margin-top: 100px;
    .text {
        color: #999;
        font-size: 14px;
        padding: 16px 0;
    }
}
</style>
