var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",style:({ height: ((this.mainHeight) + "px") })},[_c('div',{staticClass:"main-left",style:({ height: ((this.mainHeight) + "px") })},[_c('el-scrollbar',[_c('left-menu')],1)],1),_c('div',{class:['main-container', { 'main-container-bread': !_vm.isShow && _vm.isShowBrea }]},[(_vm.isShow)?_c('mdos-page-header',{key:_vm.random,attrs:{"propsData":_vm.headerData}}):_vm._e(),(!_vm.isShow && _vm.isShowBrea && (_vm.listName || _vm.childName.name))?_c('div',{staticClass:"breadcrumb"},[(!_vm.childName.name)?_c('div',[_vm._v(_vm._s(_vm.listName))]):_c('div',[_c('span',{staticClass:"det",on:{"click":_vm.goBlack}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v(" "+_vm._s(_vm.childName.name))])])]):_vm._e(),_c('el-scrollbar',{ref:"scrollbar"},[_c('div',{class:[
                    'main-container-scrollbar',
                    { 'breadcrumb-style': !_vm.isShow && _vm.isShowBrea }
                ],style:({
                    height: ((!_vm.isShow && _vm.isShowBrea && (_vm.listName || _vm.childName.name)
                            ? this.mainHeight - 80
                            : this.mainHeight - 40) + "px")
                })},[_vm._t("default")],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }