<template>
    <div class="msg-content">
        <div class="title">
            <div class="text">{{ data.name }}</div>
            <div class="time">{{ data.createTime }}</div>
        </div>
        <div class="main">{{ data.content }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {},
    props: {
        data: Object
    }
};
</script>

<style lang="less" scoped>
.msg-content {
    padding: 32px;
    .title {
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 25px;
        position: relative;
        .text {
            font-size: 24px;
            font-weight: bold;
        }
        .time {
            position: absolute;
            right: 0;
            top: 10px;
            color: #999;
        }
    }
    .main {
        padding: 24px 0;
        text-indent: 2em;
        line-height: 24px;
        color: #666;
    }
}
</style>
