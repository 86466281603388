<template>
    <app-main>
        <template v-slot:main>
            <div class="my-app-list" :style="{ minHeight: `${mainHeight - 88}px` }">
                <!-- <workbench></workbench> -->
                <app-black
                    title="已授权的业务应用"
                    :data="busiAppList"
                    style="margin-bottom:16px"
                ></app-black>
                <app-black title="已授权的数据应用" :data="dataAppList"></app-black>
            </div>
        </template>
    </app-main>
</template>
<script>
import { mapState } from 'vuex';
// import leftMenu from '@/components/layout/leftMenu';
import AppMain from '@/components/layout/appMain';
import AppBlack from './appBlack';
//import Workbench from './workbench';

export default {
    name: 'MyAppList',
    components: {
        AppBlack,
        // leftMenu,
        AppMain
        //Workbench
    },
    computed: {
        ...mapState(['myAppList', 'mainHeight']),
        busiAppList() {
            return (
                (this.myAppList && this.myAppList.filter(item => item.applicationType === 1)) || []
            );
        },
        dataAppList() {
            return (
                (this.myAppList && this.myAppList.filter(item => item.applicationType === 2)) || []
            );
        },
        appListStyle() {
            return {
                minHeight: `${this.mainHeight - 88}px`
            };
        }
    },
    methods: {},
    mounted() {}
};
</script>

<style lang="less" scoped>
.my-app-list {
    width: 1248px;
    margin: 32px auto;
}
@media screen and (max-width: 1280px) {
    .my-app-list {
        width: 932px;
    }
}
</style>
