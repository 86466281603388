<template>
    <div class="app-list-box">
        <mdos-anchor className="app-tab" container="#scrollbox" :ready="loading">
            <mdos-anchor-link
                className="app-tab-link"
                href="#service"
                title="业务应用"
            ></mdos-anchor-link>
            <mdos-anchor-link
                className="app-tab-link"
                href="#data"
                title="数据应用"
            ></mdos-anchor-link>
        </mdos-anchor>
        <div class="app-list-center" id="scrollbox" :style="mainStyle">
            <div class="app-list-main">
                <div id="service" style="margin-bottom: 24px">
                    <app-lib-black title="业务应用" :data="serviceAppList"></app-lib-black>
                </div>
                <div id="data">
                    <app-lib-black title="数据应用" :data="dataAppList"></app-lib-black>
                </div>
            </div>
        </div>
        <el-backtop target=".app-list-center">
            <i class="iconfont">&#xe64a;</i>
        </el-backtop>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import appLibBlack from './appLibBlack';

export default {
    name: 'AppListCenter',
    data() {
        return {
            searchInput: '',
            type: 1,
            loading: false
        };
    },
    components: {
        appLibBlack
    },
    computed: {
        ...mapState(['mainHeight']),
        ...mapGetters(['serviceAppList', 'dataAppList']),
        mainStyle() {
            return {
                height: `${this.mainHeight - 100}px`
            };
        }
    },

    methods: {
        ...mapActions(['getApplicationList']),

        async getAppList() {
            this.loading = true;
            await this.getApplicationList({ pageSize: 1000 });
            this.loading = false;
        }
    },
    mounted() {
        this.getAppList();
    }
};
</script>

<style lang="less" scoped>
/deep/.el-backtop {
    height: 45px;
    width: 45px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    i {
        font-size: 20px;
        color: #dcdfe6;
    }
}
.app-list-box {
    background: #f4f4f4;
    .app-tab {
        width: 1248px;
        margin: 0 auto;
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;
        .app-tab-link {
            text-decoration: none;
            border: 1px solid #dcdfe6;
            font-size: 14px;
            color: #333;
            padding: 9px 16px;
            background-color: #fff;
            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &:hover {
                color: #1089e9;
                border-color: #1089e9;
            }
            &.active {
                background-color: #1089e9;
                border-color: #1089e9;
                color: #fff;
            }
        }
    }
    .app-list-main {
        margin-bottom: 100px;
    }
}
.app-list-center {
    overflow-y: auto;
    background-color: #f4f4f4;

    .header {
        margin-bottom: 60px;
        .header-title {
            font-family: Source Han Sans CN;
            font-size: 30px;
            font-weight: 400;
            line-height: 51px;
            color: rgba(51, 51, 51, 1);
            text-align: center;
        }
        .header-notice {
            width: 454px;
            margin: 0 auto;
            text-align: center;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: rgba(51, 51, 51, 1);
        }
        .aheader-search {
            display: block;
            width: 226px;
            height: 38px;
            margin: 0 auto;
            margin-top: 30px;
        }
    }

    &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;

        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;

        background-color: rgba(144, 147, 153, 0.3);
        &:hover {
            background-color: rgba(144, 147, 153, 0.5);
        }
    }
    &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: none;
        border-radius: 10px;
        background: #ededed;
    }
}

@media screen and (max-width: 1280px) {
    .app-list-box {
        .app-tab {
            width: 932px;
        }
    }
}
</style>
