<template>
    <vue-draggable-resizable
        class-name="draggable"
        :x="draggableX"
        :y="draggableY"
        :w="draggableW"
        :h="draggableH"
        :z="9999"
        :lock-aspect-ratio="true"
        :parent="true"
        v-if="visible"
    >
        <i @click="handleClose" class="close el-icon-circle-close"></i>
        <div class="draggable-container"></div>
        <video height="100%" width="100%" :src="videoLink" controls="controls" autoplay="autoplay">
            您的浏览器不支持 video 标签。
        </video>
    </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from '@mdos/vue-draggable-resizable';
export default {
    data() {
        return {};
    },
    methods: {
        handleClose() {
            this.$emit('update:visible');
        }
    },
    computed: {
        draggableX() {
            return document.body.offsetWidth / 2 - this.draggableW / 2;
        },
        draggableY() {
            return document.body.offsetHeight / 2 - this.draggableH / 2;
        },
        draggableW() {
            return (document.body.offsetWidth / 100) * 40;
        },
        draggableH() {
            return (this.draggableW / 16) * 9;
        }
    },
    components: {
        VueDraggableResizable
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        videoLink: {
            type: String,
            default: ''
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.draggable {
    position: absolute;
    top: 0;
    left: 0;
    cursor: move;
    background-color: #fff;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .draggable-container {
        width: 100%;
        height: 80%;
        position: absolute;
        z-index: 99999;
    }

    /deep/ .handle.handle-br {
        box-sizing: border-box;
        position: absolute;
        width: 10px;
        height: 10px;
        // background: red;
        cursor: nw-resize;
        bottom: -10px;
        right: -10px;
    }

    .close {
        position: absolute;
        top: -24px;
        right: -24px;
        color: #409eff;
        font-weight: bold;
        font-size: 24px;
        opacity: 0.8;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
}
</style>
