<template>
    <!-- <el-dialog title="" :visible.sync="dialogTableVisible">
        <div class="preview-image">
            <el-image :alt="alt" :src="url"> </el-image>
        </div>
    </el-dialog> -->
    <div>
        <el-image
            :alt="alt"
            :src="url"
            :preview-src-list="list"
            v-if="list"
            ref="previewImage"
        ></el-image>
    </div>
</template>
<script>
export default {
    name: 'preview',
    data() {
        return {
            dialogTableVisible: false,
            list: [],
            url: ''
        };
    },
    props: {
        alt: {
            type: String,
            default: ''
        }
    },
    mounted() {},
    methods: {
        show(item, list) {
            this.url = item;
            this.list = list;
            // this.dialogTableVisible = true;
            this.$refs.previewImage.clickHandler();
        }
    }
};
</script>

<style lang="less" scoped>
.preview-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
}
</style>
