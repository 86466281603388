import styled from '@mdos/vue-styled-components';
const divProps = { version: String };
// const AppStyled = styled('div', divProps)`
//     @font-face {
//         font-family: 'iconfont'; /* project id 2173529 */
//         src: url('//at.alicdn.com/t/${props => props.version}.eot');
//         src: url('//at.alicdn.com/t/${props => props.version}.eot?#iefix')
//                 format('embedded-opentype'),
//             url('//at.alicdn.com/t/${props => props.version}.woff2') format('woff2'),
//             url('//at.alicdn.com/t/${props => props.version}.woff') format('woff'),
//             url('//at.alicdn.com/t/${props => props.version}.ttf') format('truetype'),
//             url('//at.alicdn.com/t/${props => props.version}.svg#iconfont') format('svg');
//     }
// `;
const AppStyled = styled('div', divProps)`
    @font-face {
        font-family: 'iconfont'; /* Project id 2173529 */
        src: url('./assets/iconfont/iconfont.woff2?t=1679554316803') format('woff2'),
            url('./assets/iconfont/iconfont.woff?t=1679554316803') format('woff'),
            url('./assets/iconfont/iconfont.ttf?t=1679554316803') format('truetype');
    }
`;
export default AppStyled;
