<template>
    <div class="head-oper-full" @click="clickEvent">
        <el-tooltip class="item" effect="dark" :content="tooltipContent" placement="bottom">
            <i class="iconfont" v-html="iconUnicode"></i>
        </el-tooltip>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isFullscreen, exitFullscreen, fullscreen } from '@mdos/utils';

export default {
    name: 'HeadOperFull',
    data() {
        return {
            //  fullscreenState: false // 全屏状态
        };
    },
    computed: {
        ...mapState(['fullscreenState']),
        tooltipContent() {
            return this.fullscreenState ? '退出全屏' : '全屏';
        },
        iconUnicode() {
            return this.fullscreenState ? '&#xe611;' : '&#xe60e;';
        }
    },

    methods: {
        ...mapActions(['setState']),

        clickEvent() {
            if (this.fullscreenState) {
                exitFullscreen();
            } else {
                fullscreen();
            }
        }
    },
    mounted() {
        window.addEventListener(
            'resize',
            () => {
                const documentHeight = document.body.offsetHeight;

                this.setState({
                    mainHeight: documentHeight - 60,
                    fullscreenState: isFullscreen()
                });
            },
            false
        );
    }
};
</script>

<style lang="less" scoped>
.head-oper-full {
    cursor: pointer;
    .iconfont {
        font-size: 18px;
        color: #fff;
        margin-right: 33px;
        vertical-align: middle;
    }
}
</style>
