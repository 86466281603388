import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import routes from './routes';
import waterMark from '@/components/waterMark';

const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error);
};

Vue.use(Router);

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    document.documentElement.scrollTop = 0;
    const noInterception = ['/login', '/phoneLogin', '/sso/login']; // 不拦截的路由
    const isLogin = store.state.token || false;

    // 这样写的原因是为了解决进入重置密码状态时，多刷几次就自动进入首页，天知道为什么会这样
    // const passwordStatus = store.state.userinfo.passwordStatus || null;
    if (to.meta.title) {
        document.title = `${to.meta.title} - UMD-OS`;
    }
    if (noInterception.includes(to.path)) {
        store && store.commit('INIT_STATE');
        next();
    } else {
        // if (isLogin && passwordStatus) {
        //     next();
        // } else {
        //     next('/login');
        // }
        if (isLogin) {
            next();
        } else {
            next('/login');
        }
    }
});

let flag = true;
router.afterEach(to => {
    // 登录页，则不设置水印，如果设置了一次也不用再设置
    if (
        to.path != '/login' &&
        to.path != '/sso/login' &&
        to.path != '/phoneLogin' &&
        flag &&
        store.state.userinfo.realName
    ) {
        flag = false;
        waterMark.set(store.state.userinfo.realName);
    }
});

export default router;
