import { get } from 'lodash';

export default {
    // 菜单
    menu: state => {
        const accessDtoTree = get(state, 'access.accessDtoTree', null);

        return accessDtoTree;
    },

    // 权限code
    pageBtnsCode: state => {
        const codes = get(state, 'access.codes', []);

        return codes.join(',');
    },

    // 业务应用
    serviceAppList: state => state.appList.filter(item => item.applicationType === 1),

    // 数据应用
    dataAppList: state => state.appList.filter(item => item.applicationType === 2)
};
