import request from '@mdos/services';
import config from '@mdos/config';
import store from '@/store';

const { MDOS_MESSAGE_MGMT, MDOS_SYSTEM_MGMT } = config.prefix;
//获取网页pc消息列表 pc
export async function msgPcList(params) {
    return request({
        url: `${MDOS_MESSAGE_MGMT}/v1/message/user/pc/listRecord`,
        method: 'GET',
        params
    });
}
// 统计用户未读的信息数量 pc
export async function unreadPc(params) {
    return request({
        url: `${MDOS_MESSAGE_MGMT}/v1/message/user/pc/total/unread`,
        method: 'get',
        params
    });
}

// 标记消息为已读
export async function readMessage(id) {
    return request({
        url: `${MDOS_MESSAGE_MGMT}/v1/message/user/${id}/readMessage`,
        method: 'POST'
    });
}

// ............................................
// 获取消息列表
export async function msgList(params) {
    return request({
        url: `${MDOS_MESSAGE_MGMT}/v1/message/user/listRecord`,
        method: 'GET',
        params
    });
}

// 标记消息为已读
export async function msgRead(data) {
    return request({
        url: `${MDOS_MESSAGE_MGMT}/v1/message/user/readMessage`,
        method: 'POST',
        params: data
    });
}

// 通过公告id获取公告详情
export async function msgNotice(params) {
    return request({
        url: `${MDOS_MESSAGE_MGMT}/v1/message/user/getNotice`,
        method: 'GET',
        params
    });
}

// 通过公告id获取公告详情
export async function msgTotalUnread(params) {
    return request({
        url: `${MDOS_MESSAGE_MGMT}/v1/message/user/total/unread`,
        method: 'GET',
        params
    });
}

// 取消应用订阅
export async function cancelSubscribe(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/application/cancel/subscribe`,
        method: 'POST',
        params
    });
}

// 推荐应用
export async function recommendList(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/application/recommend/list`,
        method: 'GET',
        params
    });
}

// 应用详情
export async function applicationGet(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/application/get`,
        method: 'GET',
        params
    });
}

// 获取微应用配置
export async function getMicroappConfig(params) {
    return request({
        baseURL: config.env[config.currEnv].npsHost,
        url: `/api/config/microapp`,
        method: 'GET',
        timeout: 10000,
        withCredentials: false,
        params
    });
}

// 类型type包含有： login、app、menu、logout
/* 

content Object
    appUrl: 菜单路由,
    appName: 应用名称,
    appId: 应用id,
    menuName: 菜单名称，
    menuPath: 菜单路径,
    menuId: 菜单id
*/

export async function filterData(type, content = {}, userId) {
    let param = {
        type,
        userId: userId || store.state.userId,
        content,
        createTime: new Date().getTime()
    };
    addBuriedPoints(param);
}

// 新增埋点数据
function addBuriedPoints(data = {}) {
    return request({
        baseURL: config.env[config.currEnv].npsHost,
        url: `/api/config/buryingpoint`,
        method: 'POST',
        timeout: 10000,
        withCredentials: false,
        data
    });
}

// 获取Iconfont数据
export async function getIconfont(params) {
    return request({
        baseURL: config.env[config.currEnv].npsHost,
        url: `/api/config/iconfont`,
        method: 'GET',
        timeout: 10000,
        withCredentials: false,
        params
    });
}

// 获取手机验证码
export async function getVerifyCode(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/users/verifyCode/send`,
        method: 'POST',
        params
    });
}

// 获取验证码包含校验旧手机号
export async function getCode(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/users/verifyCode/send/new`,
        method: 'POST',
        params
    });
}

// 修改用户手机号
export async function updatePhone(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/user/info/update`,
        method: 'POST',
        data: params
    });
}

// 获取下载二维码
export async function getQrcode(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/user/qr/code/get`,
        method: 'GET',
        data: params
    });
}
// 获取应用id
export async function getApplicationId(params) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/application/info/get`,
        method: 'GET',
        params
    });
}

// 获取面包屑显示集合
export async function getBreadcrumb(params) {
    return request({
        baseURL: config.env[config.currEnv].npsHost,
        url: `/api/config/breadcrumb`,
        method: 'GET',
        timeout: 10000,
        withCredentials: false,
        params
    });
}

//获取七牛token
export async function getToken() {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/cloud/qiniu/getToken`,
        method: 'GET'
    });
}
//新增录屏反馈
export async function reportVideoAdd(data) {
    return request({
        url: `${MDOS_SYSTEM_MGMT}/v1/reportVideo/add`,
        method: 'POST',
        data
    });
}
