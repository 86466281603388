<template>
    <div class="left-menu">
        <div style="margin-bottom: 60px">
            <el-menu
                :default-active="defaultActive"
                :router="true"
                class="menu-vertical"
                :collapse="isCollapse"
            >
                <component
                    v-bind:is="item.children ? 'leftMenuSubItem' : 'leftMenuItem'"
                    v-for="(item, index) in navMenuData"
                    :activeIndexs="activeIndexs"
                    :data="item"
                    :index="index"
                    :collapse="isCollapse"
                    :key="item.id"
                ></component>
            </el-menu>
        </div>

        <div :class="['fold', { collapse: isCollapse }]" @click="isCollapse = !isCollapse">
            <div class="fold-main"><i class="iconfont" v-html="unicode"></i> 收起导航</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LeftMenuItem from './leftMenuItem';
import LeftMenuSubItem from './leftMenuSubItem';
import { filterData } from '@/services';

export default {
    data() {
        return {
            isCollapse: false,
            collection: false,
            currMenuIndex: 0, // 当前菜单指针
            activeIndexs: [], // 当前高亮菜单树级关系
            defaultActive: null
        };
    },
    computed: {
        ...mapState(['mainHeight', 'currAppInfo']),
        ...mapGetters(['menu']),
        operationTop() {
            return {
                top: `${this.mainHeight / 2 - 28}px`
            };
        },
        unicode() {
            return this.collection ? '&#xe62a;' : '&#xe629;';
        },

        // 全部菜单
        navMenuData() {
            if (!this.menu) {
                return [];
            }

            const mapChildren = (list, index) => {
                return list
                    ? list.map(item => {
                          const newItem = {
                              id: item.id,
                              name: item.name,
                              path: this.currAppInfo.hostName + item.routePath,
                              icon: index === 0 ? item.icon || '&#xe649;' : ''
                          };
                          if (!item.leafFlag) newItem.children = mapChildren(item.children);

                          return newItem;
                      })
                    : null;
            };

            return mapChildren(this.menu, 0);
        },

        // 当前菜单
        activeMenuData() {
            return this.navMenuData[this.currMenuIndex];
        },

        // 设置展开菜单
        defaultOpeneds() {
            return this.navMenuData.map(n => n.id);
        }
    },
    components: {
        LeftMenuItem,
        LeftMenuSubItem
    },
    methods: {
        // 遍历菜单进行当前路由匹配并高亮
        getCurrMenuIndex(data) {
            if (data) {
                const currPath = this.$route.path.replace(this.currAppInfo.hostName, '');

                const getMatchPathIndexs = (routes, currPath, index = []) => {
                    for (let i = 0; i < routes.length; i++) {
                        if (routes[i].routePath === currPath) {
                            if (index.length) {
                                return [...index, i];
                            }
                            return [i];
                        } else {
                            if (routes[i].children) {
                                let pathInfoIndex = getMatchPathIndexs(
                                    routes[i].children,
                                    currPath,
                                    [...index, i]
                                );

                                if (pathInfoIndex !== undefined) {
                                    return pathInfoIndex;
                                }
                            }
                        }
                    }
                };

                let activeIndexs = null;

                if (this.$route.query.parent) {
                    activeIndexs = getMatchPathIndexs(data, this.$route.query.parent);
                } else {
                    activeIndexs = getMatchPathIndexs(data, currPath);
                }

                if (activeIndexs) {
                    let activeItem = data[activeIndexs[0]];

                    if (activeIndexs.length === 2) {
                        activeItem = activeItem.children[activeIndexs[1]];
                    }
                    if (activeIndexs.length === 3) {
                        activeItem = activeItem.children[activeIndexs[1]].children[activeIndexs[2]];
                    }
                    // if (activeIndexs.length === 4) {
                    //     activeItem =
                    //         activeItem.children[activeIndexs[1]].children[activeIndexs[2]].children[
                    //             activeIndexs[3]
                    //         ];
                    // }
                    this.selectObj(activeItem);
                }

                if (activeIndexs) {
                    this.currMenuIndex = activeIndexs[0];
                    //this.activeIndexs = activeIndexs;

                    let currMenu = [...this.navMenuData];

                    for (let i = 0; i < activeIndexs.length; i++) {
                        if (currMenu[activeIndexs[i]].children) {
                            currMenu = currMenu[activeIndexs[i]].children;
                        } else {
                            currMenu = [currMenu[activeIndexs[i]]];
                            break;
                        }
                    }

                    this.defaultActive = currMenu[0].path;
                }
            }
        },

        handleNavChnage(index) {
            this.currMenuIndex = index;
            if (this.activeMenuData.children[0]) {
                if (this.activeMenuData.children[0].children) {
                    this.$router.push(this.activeMenuData.children[0].children[0].path);
                } else {
                    this.$router.push(this.activeMenuData.children[0].path);
                }
            }
        },
        selectObj(item) {
            let param = {
                appUrl: '',
                appName: '',
                appId: '',
                menuName: item.name,
                menuPath: item.routePath,
                menuId: item.id
            };
            filterData('menu', param);
        }
    },
    watch: {
        '$route.path': {
            handler() {
                this.getCurrMenuIndex(this.menu);
            },
            immediate: true
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.left-menu {
    .menu-vertical:not(.el-menu--collapse) {
        width: 244px;
        min-height: 400px;
    }
    /deep/ .el-menu {
        border-right: none;
    }
    /deep/.el-menu--collapse {
        width: 48px;
    }
    /deep/.el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
        margin-left: -4px;
    }

    /deep/.el-submenu {
        .el-menu-item {
            height: 40px !important;
            line-height: 40px !important;
        }
    }

    .fold {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 245px;
        height: 61px;
        color: #666;
        line-height: 61px;
        box-sizing: border-box;
        font-size: 14px;
        border-right: 1px solid #e8e8e8;
        cursor: pointer;
        background-color: #fff;
        transition: all 0.2s ease-in;
        z-index: 999;
        .fold-main {
            height: 40px;
            line-height: 40px;
            margin-top: 20px;
            border-top: 1px solid #ebeef5;
        }
        &.collapse {
            width: 49px;
            overflow: hidden;
        }
        i {
            margin-left: 13px;
            font-size: 18px;
            vertical-align: bottom;
            color: #666;
            margin-right: 8px;
        }
    }
}
</style>
