<template>
    <div class="record-bar" v-if="recordVisible">
        <span>录屏工具栏</span>
        <span class="line"></span>
        <span :class="recording ? ['mr25', 'blue'] : 'mr25'" @click="recordClicked">
            <img
                :src="
                    recording
                        ? require('../../assets/img/record/stop.png')
                        : require('../../assets/img/record/start.png')
                "
            />
            {{ recording ? '停止' : '录制' }}</span
        >
        <span :class="stoped ? ['mr40', 'blue'] : 'mr40'" @click="playClicked">
            <img
                :src="
                    stoped
                        ? require('../../assets/img/record/pre_nor.png')
                        : require('../../assets/img/record/pre_dis.png')
                "
            />预览</span
        >
        <span @click="close"><img src="../../assets/img/record/close.png" alt=""/></span>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            currentWebmData: 0,
            recording: false,
            recorder: null,
            stoped: false,
            stream: null,
            videoLong: 0,
            startTimeStamp: 0,
            endTimeStamp: 0
        };
    },
    props: {
        recordVisible: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        recordVisible(val) {
            if (val) {
                this.reset();
            }
        }
    },
    mounted() {},
    methods: {
        ...mapActions(['setState']),
        close() {
            this.$emit('update:recordVisible', false);
        },
        reset() {
            this.currentWebmData = 0;
            this.videoLong = 0;
            this.stoped = false;
            this.recording = false;
            if (this.recorder) {
                this.recorder.stop();
                this.stream = null;
                this.recorder = null;
            }
        },
        async init() {
            // 录屏
            await navigator.mediaDevices.getDisplayMedia().then(screenStream => {
                screenStream.getVideoTracks()[0].onended = async () => {
                    this.stopClicked();
                };
                navigator.mediaDevices
                    .getUserMedia({ video: false, audio: true })
                    .then(mic => {
                        //有音频设备添加音频
                        screenStream.addTrack(mic.getTracks()[0]);
                        this.stream = screenStream;
                        this.initRecord();
                    })
                    .catch(() => {
                        this.stream = screenStream;
                        this.initRecord();
                    });
            });
        },
        //初始化录屏
        initRecord() {
            this.recorder = new MediaRecorder(this.stream, {
                mimeType: 'video/webm;codecs=h264'
            });
            this.startRecord();
            this.recorder.ondataavailable = this.recorder_dataAvailableHandler.bind(this);
        },
        recorder_dataAvailableHandler(e) {
            this.currentWebmData = 0;
            this.endTimeStamp = new Date().getTime();
            this.videoLong = Number(this.endTimeStamp) - Number(this.startTimeStamp);
            this.currentWebmData = e.data;
        },
        //点击录制或停止
        recordClicked() {
            if (this.recording) {
                this.stopClicked();
            } else {
                this.init();
            }
        },
        //开始录制
        startRecord() {
            this.startTimeStamp = new Date().getTime();
            this.currentWebmData = 0;
            this.stoped = false;
            this.recording = true;
            this.recorder.start();
        },
        //停止
        stopClicked() {
            this.stream.getTracks().forEach(track => track.stop());
            this.stoped = true;
            this.recording = false;
            this.recorder = null;
        },
        //预览
        playClicked() {
            if (this.stoped) {
                let binaryData = [];
                binaryData.push(this.currentWebmData);
                let url = window.URL.createObjectURL(new Blob(binaryData));
                this.setState({
                    preVideoSrc: ''
                });
                setTimeout(() => {
                    this.setState({
                        videoLong: this.videoLong,
                        preVideoSrc: url,
                        webmData: this.currentWebmData
                    });
                }, 0);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.record-bar {
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    width: 350px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    color: #ffffff;
    padding: 0 18px 0 24px;
    box-sizing: border-box;
    span {
        line-height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }
    .line {
        width: 1px;
        height: 16px;
        background-color: #ffffff4d;
        margin: 0 16px;
    }
    .blue {
        color: #4876ff;
    }
    .mr25 {
        margin-right: 25px;
    }
    .mr40 {
        margin-right: 40px;
    }
}
</style>
