<template>
    <div class="login">
        <phone @passwordChange="pwdVisible = true"></phone>
        <el-dialog :visible.sync="pwdVisible" fullscreen :show-close="false">
            <pwd-change></pwd-change>
        </el-dialog>
    </div>
</template>

<script>
import pwdChange from '../pwdChange';
import Phone from '@/components/login/phoneLogin';
export default {
    name: 'phoneLogin',
    data: () => {
        return {
            pwdVisible: false
        };
    },
    components: {
        pwdChange,
        Phone
    }
};
</script>
<style lang="less" scoped>
.login {
    /deep/ .el-dialog__header {
        padding: 0;
    }
    /deep/ .el-dialog__body {
        padding: 0;
    }
}
</style>
