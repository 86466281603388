<template>
    <!-- <el-carousel :interval="5000" :arrow="arrow" indicator-position="none">
        <el-carousel-item v-for="(item, index) in data" :key="index">
            <img style="width: 100%" :src="item" />
        </el-carousel-item>
    </el-carousel> -->
    <div class="swiper-box">
        <div class="swiper-container">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide v-for="(item, index) in list" :key="index">
                    <!-- <img :src="item" class="photos" /> -->
                    <!-- <img :src="item" class="photos" @click="onPhotos(item, list)" /> -->
                    <el-image :src="item" class="photos" @click="onPhotos(item)"></el-image>
                </swiper-slide>
                <!-- <swiper-slide><img src="~@/assets/img/pass-bg.png" class="photos" /></swiper-slide>
                <swiper-slide><img src="~@/assets/img/bg.jpg" class="photos" /></swiper-slide>
                <swiper-slide><img src="~@/assets/img/pass-bg.png" class="photos" /></swiper-slide>
                <swiper-slide><img src="~@/assets/img/pass-bg.png" class="photos" /></swiper-slide> -->
            </swiper>
        </div>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        <div class="swiper-button-prev" slot="pagination" v-if="list.length > 1"></div>
        <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <div class="swiper-button-next" slot="pagination" v-if="list.length > 1"></div>
        <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <el-image
            :src="url"
            :preview-src-list="list"
            ref="previewImage"
            v-show="previewShow"
        ></el-image>
    </div>
</template>
<script>
export default {
    name: 'carousel',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 1.3, //显示个数,
                prevButton: '.swiper-button-prev', //上一页
                nextButton: '.swiper-button-next' //下一页
            },
            url: '',
            previewShow: false
        };
    },
    // props: {
    //     data: {
    //         type: Array,
    //         default: () => []
    //     }
    // },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
    methods: {
        onPhotos(item) {
            this.previewShow = true;
            this.url = item;
            this.$refs.previewImage.clickHandler();
            //this.$emit('preview', { url: item });
        }
    },
    mounted() {
        // this.swiper.slideTo(2, 1000, false);
    }
};
</script>

<style lang="less" scoped>
.swiper-box {
    position: relative;
}
.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 480px;
    width: 100%;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding-right: 16px;
    box-sizing: border-box;
    &:last-child {
        padding-right: 0px;
    }
    .photos {
        height: 480px;
        width: 100%;
    }
}
/*Swiper原样式 */
/deep/ .swiper-button-prev {
    height: 72px;
    width: 72px;
    margin-top: -36px;
    left: -36px;
    background-size: contain;
    background-image: url('~@/assets/img/pic_turnleft.png');
}
// /*改变了颜色和加粗的样式*/
/deep/ .swiper-button-next {
    height: 72px;
    width: 72px;
    margin-top: -36px;
    right: -36px;
    background-size: contain;
    background-image: url('~@/assets/img/pic_turnright.png');
}
/deep/.el-image__preview {
    display: none;
}
</style>
