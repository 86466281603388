import msgState from './modules/message/state';
export const initState = {
    message: msgState,
    token: null,
    userId: null,
    myAppList: null,
    appList: [],
    userinfo: {},
    access: {},
    mainHeight: 0, // 主体高度
    videoSrc: null, // 视频链接
    preVideoSrc: null, // 预览录屏链接
    webmData: null, //预览视频的二进制流
    videoLong: 0, //视频时长
    showRecording: false, //显示录制toolbar
    currAppInfo: null, // 当前app信息
    fullscreenState: false, // 是否全屏
    // iconfontVersion: 'font_2173529_2o2k913vcis',
    iconfontVersion: 'font_2173529_kez4dxir2f',
    appInfo: {},
    accessList: {},
    pagelistStore: {}, // pageListMore组件缓存
    pageQuerylistStore: {}, // pageQueryList组件缓存
    msgTotalUnread: 0 //消息提醒条数
};

export default {
    ...initState
};
