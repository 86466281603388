import MicroApp from '@/pages/micro/index';
import MicroAppFull from '@/pages/micro/full';
import Home from '@/pages/home/index';
import Container from '@/pages/container/index';
import MyAppList from '@/pages/center/myAppList';
import AppListCenter from '@/pages/center/appListCenter';
import AppDetail from '@/pages/center/appDetail';
import Message from '@/pages/message';
import MsgList from '@/pages/msgList';
import PhoneLogin from '@/pages/phoneLogin/index';
export default [
    {
        path: '/',
        name: 'home',
        component: Home,
        redirect: '/container',
        meta: { title: '首页' },
        children: [
            {
                path: '/container',
                name: 'container',
                component: Container,
                redirect: '/workCenter/myAppList',
                children: [
                    {
                        path: '/workCenter/myAppList',
                        name: 'myAppList',
                        component: MyAppList,
                        meta: { title: '我的应用' }
                    },
                    {
                        path: '/workCenter/editCard',
                        name: 'editCard',
                        component: () =>
                            import(
                                /* webpackChunkName: "editCard" */ '@/pages/center/editCard.vue'
                            ),
                        meta: { title: '工作卡片设置' }
                    },
                    {
                        path: '/workCenter/appListCenter/:name',
                        name: 'appListCenter',
                        component: AppListCenter,
                        meta: { title: '应用库' }
                    },
                    {
                        path: '/message',
                        component: Message
                    },
                    {
                        path: '/msgList',
                        component: MsgList
                    },
                    {
                        path: '/workCenter/appDetail',
                        name: 'appDetail',
                        component: AppDetail,
                        meta: { title: '应用详情' }
                    }
                ]
            },
            {
                path: '/microApp/*',
                component: MicroApp
            },
            {
                path: '/microAppFull/*',
                component: MicroAppFull
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/login/index.vue'),
        meta: {
            title: '登录'
        },
        children: []
    },
    {
        path: '/sso/login',
        name: 'ssologin',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/ssologin/index.vue'),
        meta: {
            title: '登录'
        },
        children: []
    },
    {
        path: '/phoneLogin',
        name: 'phoneLogin',
        component: PhoneLogin,
        meta: {
            title: '登录'
        }
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "404" */ '@/pages/errors/404.vue'),
        meta: { title: '404' }
    }
    // {
    //     path: '*',
    //     redirect: '/404'
    // }
];
