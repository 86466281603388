<template>
    <div id="app">
        <app-styled :version="iconfontVersion">
            <router-view />
        </app-styled>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getIconfont } from '@/services';
import appStyled from './appStyled';
export default {
    name: 'App',
    data() {
        return {};
    },
    computed: {
        ...mapState(['iconfontVersion'])
    },
    methods: {
        ...mapActions(['setState']),
        async reqIconfont() {
            const res = await getIconfont({
                projectId: '2173529'
            });

            if (res.resultCode === '0') {
                this.version = res.data.version;

                if (this.version !== this.iconfontVersion) {
                    this.setState({
                        iconfontVersion: this.version
                    });
                }
            }
        }
    },

    components: {
        appStyled
    },

    mounted() {
        this.reqIconfont();
    }
};
</script>
