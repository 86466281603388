<template>
    <div id="micro">
        <!-- 子应用渲染区，用于挂载子应用节点 -->
        <section id="subapp-viewport"></section>
    </div>
</template>

<script>
export default {
    name: 'home',
    data() {
        return {};
    },
    computed: {},
    components: {}
};
</script>
