<template>
    <div class="head-message head-message2" @click="clickEvent">
        <el-tooltip class="item" effect="dark" content="消息通知" placement="bottom">
            <el-badge :value="msgTotalUnread" :hidden="!msgTotalUnread" class="head-message-badge">
                <i :class="['iconfont']"><img :src="iconSrc"/></i>
            </el-badge>
        </el-tooltip>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            active: true,
            iconSrc: require('@/assets/img/mail.svg')
        };
    },
    computed: {
        ...mapState(['msgTotalUnread'])
    },
    watch: {
        // '$route.path': {
        //     handler(path) {
        //     },
        //     immediate: true
        // }
    },
    methods: {
        clickEvent() {
            window.localStorage.removeItem('main-msg-page-num');
            window.localStorage.removeItem('main-msg-page-size');
            if (this.$route.path !== '/msgList') {
                this.$router.push({ path: '/msgList' });
                return;
            }
            window.location.reload();
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.head-message2 {
    margin-right: 35px;
}

.head-message {
    cursor: pointer;

    .iconfont {
        font-size: 38px;
        color: #fff;
        vertical-align: middle;
    }

    .head-message-badge {
        /deep/ .el-badge__content {
            top: 20px;
        }
    }
}
</style>
