import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from '@mdos/vuex-persistedstate'; // 数据持久化
import config from '@mdos/config';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const vuexLocal = persistedState({
    key: config.storageKey,
    storage: window.localStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    plugins: [vuexLocal]
});
