<template>
    <div class="message" :style="{ height: `${mainHeight - 20}px` }">
        <div class="title">
            消息通知
        </div>
        <div class="mess-main">
            <mdos-table
                :data="tableData"
                :columns="columns"
                @pageNumChange="handlePageNumChange"
                @pageSizeChange="handlePageSizeChange"
                @operateClick="handleOperateClick"
                :columnsIndex="false"
                :row-class-name="tableRowClassName"
            >
                <template v-slot:content="scope">
                    <span
                        ><i class="dotClass" v-if="scope.row.status == 1"></i
                        >{{ scope.row.content }}</span
                    >
                </template>
            </mdos-table>
            <el-pagination
                style="margin-top:10px"
                v-if="pagination.total"
                background
                @size-change="handlePageSizeChange"
                @current-change="handlePageNumChange"
                :current-page="pagination.currentPage"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pagination.pageSize"
                layout="prev, pager, next,jumper,total,sizes"
                :total="pagination.total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { msgPcList, readMessage } from '@/services';
export default {
    data() {
        return {
            pagination: { total: 0, currentPage: 1, pageSize: 10 },
            columns: [
                { prop: 'content', label: '消息内容', minWidth: 200, itemSlot: 'content' },
                {
                    itemType: 'operate',
                    prop: 'cz',
                    label: '操作',
                    options: [{ id: 'ck', text: '查看' }],
                    width: 150
                }
            ],
            tableData: []
        };
    },
    computed: {
        ...mapState({ mainHeight: 'mainHeight' })
    },
    methods: {
        ...mapActions(['unreadPcTotal']),
        async getData() {
            this.pagination.pageSize = window.localStorage['main-msg-page-size']
                ? parseInt(window.localStorage['main-msg-page-size'])
                : this.pagination.pageSize;
            this.pagination.currentPage = window.localStorage['main-msg-page-num']
                ? parseInt(window.localStorage['main-msg-page-num'])
                : this.pagination.currentPage;
            let params = {
                pageNum: window.localStorage['main-msg-page-num']
                    ? window.localStorage['main-msg-page-num']
                    : this.pagination.currentPage,
                pageSize: window.localStorage['main-msg-page-size']
                    ? window.localStorage['main-msg-page-size']
                    : this.pagination.pageSize,
                channelType: 4,
                messageType: 7
            };
            let { resultCode, data } = await msgPcList(params);
            if (resultCode == 0) {
                this.tableData = data.list;
                this.pagination.total = data.total;
            }
        },
        handlePageNumChange(val) {
            this.pagination.currentPage = val;
            window.localStorage.setItem('main-msg-page-num', val);
            this.getData();
        },
        handlePageSizeChange(val) {
            this.pagination.currentPage = 1;
            this.pagination.pageSize = val;
            window.localStorage.setItem('main-msg-page-num', 1);
            window.localStorage.setItem('main-msg-page-size', val);
            this.getData();
        },
        handleOperateClick({ id, row } = id) {
            if (id == 'ck') {
                window.open(
                    `/microApp/isd/pages/merchant/opportunityManagement/detail?id=${row.params.sourceId}`
                );
                this.readMsg(row.id);
            }
        },
        async readMsg(params) {
            let { resultCode } = await readMessage(params);
            if (resultCode == 0) {
                this.unreadPcTotal();
                this.getData();
            }
        },
        //自定义行样式
        tableRowClassName({ row }) {
            if (row.status === 1) {
                return 'row-no-read';
            }
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style lang="less" scoped>
.title {
    font-size: 16px;
    color: #333;
    margin-bottom: 16px;
}
.message {
    background-color: #fafafa;
    padding: 24px;
    box-sizing: border-box;
    .mess-main {
        box-sizing: border-box;
        padding: 16px 0;
        background-color: #fff;
        height: 100%;
        border-radius: 2px;
    }
    .mess-label {
        padding: 9px 12px;
    }
    /deep/ .el-tabs {
        .el-tabs__header {
            padding: 0 32px;
            margin-bottom: 0;
        }
        .el-tabs__item {
            padding: 0 7px;
        }
        .el-tabs__nav-scroll {
            padding-bottom: 16px;
        }
        .el-tabs__active-bar {
            display: none;
        }
        .is-active {
            .mess-label {
                color: #096dd9;
                background: rgba(9, 109, 217, 0.1);
                border-radius: 4px;
            }
        }
        .el-tabs__nav-wrap::after {
            background-color: #eee;
            height: 1px;
        }
    }
}
/deep/.el-table .row-no-read {
    background: #f8f9fc;
    td {
        font-weight: bold;
    }
}
.dotClass {
    position: absolute;
    left: 5px;
    top: 20px;
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
}
</style>
