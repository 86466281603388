<template>
    <el-menu class="let-menu" active-text-color="#999999">
        <el-menu-item-group>
            <template slot="title">
                已授权应用
            </template>
            <el-menu-item :index="item.path" v-for="(item, index) in data" :key="index">
                <div @click="clickEvent(item)">
                    <img :src="item.icon" class="icon-img" />
                    {{ item.name }}
                    <div class="no-auth" v-if="item.hasAuthorization === 0">无权限</div>
                </div>
            </el-menu-item>
        </el-menu-item-group>
    </el-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { filterData } from '@/services';
export default {
    data() {
        return {};
    },
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapState(['appInfo'])
    },
    methods: {
        ...mapActions(['getUsersAccess', 'setState']),
        async clickEvent(item) {
            if (item.hasAuthorization === 0) {
                return;
            }
            filterData('app', {
                appUrl: item.hostName,
                appName: item.name,
                appId: item.id,
                menuName: '',
                menuPath: '',
                menuId: ''
            });
            const hostName = item.hostName;

            if (hostName) {
                if (hostName.includes('http://') || hostName.includes('http://')) {
                    window.location.href = hostName;
                } else {
                    this.appInfo[hostName] = item;
                    this.setState({
                        appInfo: this.appInfo
                    });

                    const res = await this.getUsersAccess({ applicationId: item.id, hostName });
                    if (res.resultCode === '0') {
                        this.$router.push(hostName);
                        // window.location.href = hostName;
                    }
                }
            } else {
                this.$message.error('应用暂时无法使用！');
            }
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.let-menu {
    height: 100%;
    border-right: none !important;
    .no-auth {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 12px;
        background-color: #b2c0cb;
        padding: 0 10px;
        line-height: 28px;
        border-radius: 4px;
        color: #fff;
    }
    /deep/ .el-menu-item {
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        color: #333;

        &:hover {
            background: #ebeef5 !important;
        }
        .icon-img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            vertical-align: sub;
        }
    }
    /deep/ .el-menu-item-group__title {
        background: #f8f8f8;
        padding: 11px 24px;
        font-size: 14px;
    }
}
</style>
