import { initState } from './state';
import { SET_STATE, INIT_STATE } from './mutation-types';
import { get } from 'lodash';

export default {
    [SET_STATE](state, data) {
        const deep = get(data, '_deep', null);

        // 是否存在二级数据
        if (deep) {
            delete data._deep;

            for (const key in data) {
                if (key in state[deep]) {
                    state[deep][key] = data[key];
                }
            }
        } else {
            for (const key in data) {
                if (key in state) {
                    state[key] = data[key];
                }
            }
        }
    },

    [INIT_STATE](state) {
        const modules = ['message'];

        for (const key in initState) {
            if (modules.includes(key)) {
                for (const childKey in initState[key]) {
                    state[key][childKey] = initState[key][childKey];
                }
            } else {
                state[key] = initState[key];
            }
        }
    }
};
