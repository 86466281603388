<template>
    <div>
        <layout-child>
            <template>
                <div id="micro">
                    <!-- 子应用渲染区，用于挂载子应用节点 -->
                    <section id="subapp-viewport"></section>
                </div>
            </template>
        </layout-child>
    </div>
</template>

<script>
import layoutChild from '@/components/layoutChild';

export default {
    data() {
        return {};
    },
    computed: {},
    components: {
        layoutChild
    }
};
</script>
