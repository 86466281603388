<template>
    <div class="app-black" v-if="data.length">
        <div class="app-title">{{ title }}</div>
        <div class="app-list">
            <app-item v-for="(item, index) in data" :key="index" :data="item"></app-item>
        </div>
    </div>
</template>
<script>
import appItem from './appItem';

export default {
    name: 'MyAppList',
    props: {
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => []
        }
    },
    components: {
        appItem
    },
    computed: {},
    methods: {},
    mounted() {}
};
</script>

<style lang="less" scoped>
.app-black {
    .app-title {
        font-size: 16px;
        color: #333;
        margin-bottom: 16px;
    }
    .app-list {
        display: flex;
        flex-wrap: wrap;
    }
}
// @media screen and (max-width: 1280px) {
//     .app-black {
//         .app-title {
//             font-size: 14px;
//         }
//     }
// }
</style>
