<template>
    <div class="modi-phone">
        <el-form ref="form" :model="form" label-width="80px" label-position="top" :rules="rules">
            <el-form-item>
                <div class="message-info">
                    <i class="el-icon-info"></i
                    ><span
                        >更换手机号后，请使用新的手机号登录，当前使用号码：{{
                            userinfo.phone
                        }}</span
                    >
                </div>
            </el-form-item>
            <el-form-item label="新手机号" prop="phone">
                <el-input
                    v-model="form.phone"
                    placeholder="请输入新手机号"
                    :disabled="disableInput"
                ></el-input>
                <el-button type="primary" plain v-if="!code" @click="getCode">获取验证码</el-button>
                <el-button v-else>有效期{{ num }}s</el-button>
            </el-form-item>
            <el-form-item label="验证码" prop="checkCode">
                <el-input v-model="form.checkCode" placeholder="请输入验证码"></el-input>
            </el-form-item>
            <el-form-item>
                <div class="btn-list">
                    <el-button @click="cancel('form')">取消</el-button>
                    <el-button type="primary" :disabled="!uniqueid" @click="submit('form')"
                        >确认</el-button
                    >
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { getCode, updatePhone } from '@/services';
const storage = {
    get() {
        let phoneCheck = sessionStorage.getItem('phoneCheck');

        phoneCheck = JSON.parse(phoneCheck);

        return phoneCheck;
    },
    set(data) {
        sessionStorage.setItem('phoneCheck', JSON.stringify(data));
    },
    remove() {
        sessionStorage.removeItem('phoneCheck');
    }
};
export default {
    data() {
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('联系人手机号不能为空！'));
            }
            if (value) {
                var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
                if (!myreg.test(value.trim())) {
                    return callback(new Error('联系人手机号不合法！'));
                }
            }
            callback();
        };
        var checkCode = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('验证码不能为空！'));
            }
            if (value) {
                var exp = /^[0-9]{4}$/;
                if (!exp.test(value.trim())) {
                    return callback(new Error('合法验证码必须为四位整数！'));
                }
            }
            callback();
        };
        return {
            form: {
                checkCode: '',
                phone: ''
            },
            disableInput: false, // 禁用输入框
            num: 60,
            uniqueid: '',
            rules: {
                phone: [{ required: true, validator: checkPhone, trigger: 'blur' }],
                checkCode: [{ required: true, validator: checkCode, trigger: 'blur' }]
            },
            timer: null,
            code: false // 是否点击获取验证码
        };
    },
    computed: {
        ...mapState(['userinfo'])
    },
    methods: {
        getCode() {
            if (/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.phone)) {
                getCode({ mobile: this.form.phone }).then(res => {
                    if (res.resultCode === '0') {
                        this.code = true;
                        this.uniqueid = res.data;
                        this.disableInput = true;

                        this.countdown();
                    }
                });
            }
            // else {
            //     this.$message.error('手机号不合法');
            // }
        },

        countdown() {
            this.timer = setInterval(() => {
                if (this.num > 0) {
                    this.num--;
                } else {
                    this.num = 60;
                    this.code = false;
                    this.disableInput = false;
                    this.clearTimer();
                }
                storage.set({
                    phoneSecond: this.num,
                    uniqueid: this.uniqueid,
                    currentTime: new Date().getTime(),
                    phone: this.form.phone
                });
            }, 1000);
        },

        clearTimer() {
            clearInterval(this.timer);
            this.disableInput = false;
            this.timer = null;
            storage.remove();
        },

        cancel() {
            this.clearTimer();
            this.$refs.form.resetFields();
            if (this.code) this.code = false;
            if (this.disableInput) this.disableInput = false;
            this.$emit('closeModal', false);
        },

        submit(formName) {
            this.$refs[formName].validate(res => {
                if (res) {
                    updatePhone({
                        ...this.form,
                        id: this.userinfo.id,
                        checkCodeUniqueId: this.uniqueid
                    }).then(res => {
                        if (res.resultCode === '0') {
                            this.$message.success('修改号码成功');
                            setTimeout(() => {
                                this.cancel();
                                this.$store.dispatch('getUsersInfo');
                            }, 500);
                        }
                    });
                }
            });
        }
    },
    beforeDestroy() {
        this.clearTimer();
    },
    mounted() {
        if (storage.get()) {
            const { phoneSecond, uniqueid, currentTime, phone } = storage.get();
            const diffTime = new Date().getTime() - +currentTime;
            if (phoneSecond && uniqueid && diffTime < +phoneSecond * 1000) {
                this.num = phoneSecond - Math.floor(diffTime / 1000);
                this.uniqueid = uniqueid;
                this.code = true;
                this.form.phone = phone;
                this.disableInput = true;
                this.countdown();
            } else {
                storage.remove();
            }
        }
    }
};
</script>
<style lang="less" scoped>
.modi-phone {
    /deep/ .el-form-item:nth-child(2) {
        .el-form-item__content {
            display: flex;
            .el-button {
                margin-left: 8px;
            }
        }
    }
    .btn-list {
        float: right;
    }
    .message-info {
        background: #e6f8ff;
        border-radius: 4px;
        border: 1px solid #b5e8ff;
        padding: 8px 16px;
        line-height: 25px;
        color: #1089e9;
        font-size: 14px;
        display: flex;
        i {
            margin-right: 12px;
            position: relative;
            top: 5px;
        }
    }
}
</style>
