<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    methods: {},
    mounted() {}
};
</script>
