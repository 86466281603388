<template>
    <div class="empty">
        <div class="empty-main">
            <img :src="imgSrc" />
            <div class="text">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {},
    props: {
        imgSrc: {
            type: String,
            default: require('@/assets/img/message/empty.png')
        },
        text: {
            type: String,
            default: '消息暂时没有哦！'
        }
    }
};
</script>

<style lang="less" scoped>
.empty {
    margin-top: 150px;
    .empty-main {
        text-align: center;
    }
    .text {
        color: #999999;
        font-size: 16px;
        margin-top: 24px;
    }
}
</style>
