<template>
    <el-container class="layout">
        <el-header class="head">
            <head-logo @onClick="visible = true"></head-logo>
            <head-nav @onClick="onNavClick"></head-nav>
            <record-toolbar ref="record"></record-toolbar>
            <head-oper
                @loginOut="loginOut"
                @updatePwd="pwdVisible = true"
                @updatePhone="phoneVisible = true"
                @showRecording="showRecording"
            ></head-oper>
        </el-header>
        <component :is="useComp">
            <slot></slot>
        </component>
        <left-drawer :visible.sync="visible">
            <left-menu :data="myAppList"></left-menu>
        </left-drawer>
        <el-dialog
            title="修改登录密码"
            :close-on-click-modal="false"
            :visible.sync="pwdVisible"
            width="358px"
        >
            <mdos-pwd-form />
        </el-dialog>
        <el-dialog
            title="修改手机号"
            :close-on-click-modal="false"
            :visible.sync="phoneVisible"
            width="358px"
        >
            <phone-dialog @closeModal="closeModal" />
        </el-dialog>
    </el-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MdosPwdForm from '@/components/pwdForm';
import phoneDialog from '@/components/phoneDialog';
import headNav from './headNav';
import headLogo from './headLogo';
import headOper from './headOper';
import leftMenu from './leftMenu';
import leftDrawer from './leftDrawer';
import emptyContainer from './emptyContainer';
import mainContainer from './mainContainer';
import recordToolbar from '@/components/record/recordToolbar';

export default {
    name: 'Layout',
    data() {
        return {
            menuType: 0,
            visible: false,
            pwdVisible: false,
            phoneVisible: false,
            recordVisible: false
        };
    },
    computed: {
        ...mapState(['myAppList']),
        isEmpty() {
            return (this.myAppList && !this.myAppList.length) || false;
        },
        useComp() {
            if (this.$route.path === '/workCenter/myAppList' && this.isEmpty) {
                return 'emptyContainer';
            } else {
                return 'mainContainer';
            }
        }
    },
    created() {
        const documentHeight = document.body.offsetHeight;

        this.setState({
            mainHeight: documentHeight - 60
        });
    },

    components: {
        headNav,
        headLogo,
        headOper,
        leftMenu,
        leftDrawer,
        emptyContainer,
        MdosPwdForm,
        mainContainer,
        phoneDialog,
        recordToolbar
    },
    methods: {
        ...mapActions(['getMyApplicationList', 'setState', 'logOut']),
        loginOut() {
            this.logOut();
        },
        onNavClick(index) {
            this.menuType = index;
        },

        closeModal(val) {
            this.phoneVisible = val;
        },
        //显示录制toolbar
        showRecording() {
            this.setState({
                showRecording: false
            });
            setTimeout(() => {
                this.setState({
                    showRecording: true
                });
            }, 0);
        }
    },
    watch: {
        '$route.path': {
            handler(path) {
                if (path === '/workCenter/myAppList') this.getMyApplicationList();
            },
            immediate: true
        }
    },
    mounted() {}
};
</script>

<style lang="less">
.layout {
    .head {
        position: relative;
        line-height: 60px;
        font-size: 14px;
        background: url('~@/assets/img/v2/pic_home_LeftBg.png') no-repeat #1089e9;
    }
}
</style>
