<template>
    <div>
        <component :is="useComp"></component>
    </div>
</template>

<script>
import BasePage from './base';
import FullPage from './full';

export default {
    name: 'home',
    components: {
        BasePage,
        FullPage
    },
    data() {
        return {};
    },
    computed: {
        useComp() {
            let useComp = 'BasePage';
            if (this.$route.query.fullScreen === '1') {
                useComp = 'FullPage';
            }

            return useComp;
        }
    }
};
</script>
