import {
    registerMicroApps,
    addGlobalUncaughtErrorHandler,
    runAfterFirstMounted,
    start
} from 'qiankun';
// import { Loading, MessageBox } from 'element-ui';
import { Loading } from '@mdos/element-ui';
import { getMicroappConfig } from '@/services';
import config from '@mdos/config';
// import NProgress from '@mdos/nprogress';

// 子应用注册信息
import apps from './apps';

let loadingInstance = null;

export default async () => {
    //const microAppName = sessionStorage.getItem('microAppName');
    let currAppInfo = null;
    // let newApps = [];

    if (config.currEnv === 'loc') {
        // if (microAppName) {
        //     newApps = apps.filter(app => app.name === microAppName);
        // } else {
        //     newApps = apps;
        // }
        // currAppInfo = newApps;
        try {
            const res = await getMicroappConfig({ _id: '60f7e4c4535f2852d150442d' });

            if (res.data) {
                currAppInfo = res.data;
            }
        } catch (error) {
            currAppInfo = apps;
        }
    } else {
        try {
            const res = await getMicroappConfig();

            if (res.data) {
                currAppInfo = res.data;
            }
        } catch (error) {
            currAppInfo = apps;
        }
    }

    /**
     * 注册子应用
     * 第一个参数 - 子应用的注册信息
     * 第二个参数 - 全局生命周期钩子
     */
    registerMicroApps(currAppInfo, {
        // qiankun 生命周期钩子 - 加载前
        beforeLoad: app => {
            // 加载子应用前，加载进度条
            // NProgress.start();
            loadingInstance = Loading.service({ text: '应用加载中...' });
            console.log('before load', app.name);

            return Promise.resolve();
        },
        // qiankun 生命周期钩子 - 挂载后
        afterMount: app => {
            // 加载子应用前，进度条加载完成
            // NProgress.done();

            loadingInstance.close();
            console.log('after mount', app.name);
            return Promise.resolve();
        }
    });

    /**
     * 添加全局的未捕获异常处理器
     */
    addGlobalUncaughtErrorHandler(event => {
        //console.error(event);
        const { message: msg } = event;
        loadingInstance.close();
        // 加载失败时提示
        if (msg && msg.includes('died in status LOADING_SOURCE_CODE')) {
            /*  if (config.currEnv !== 'loc') {
                localStorage.removeItem(config.storageKey);
                location.reload();
            }
            */
            //const isNoAuth = !!document.querySelector('.auth-empty');
            //let reload = sessionStorage.getItem('reload') || 0;
            console.log('子应用加载失败，请检查应用是否可运行');

            /* if (!isNoAuth) {
                if (reload && reload > 1) {
                    sessionStorage.removeItem('reload');
                    MessageBox.confirm('系统服务异常，请稍后再刷新页面！', '提示', {
                        confirmButtonText: '刷新',
                        cancelButtonText: '返回',
                        type: 'warning',
                        callback: action => {
                            if (action === 'confirm') {
                                location.reload();
                            } else {
                                location.href = '/';
                            }
                        }
                    });
                } else {
                    setTimeout(() => {
                        sessionStorage.setItem('reload', +reload + 1);
                        location.reload();
                    }, 1000);
                } 
            }*/
        }
    });

    runAfterFirstMounted(() => {
        loadingInstance.close();
        console.log('[MainApp] first app mounted');
    });

    start({
        // sandbox: {
        //     strictStyleIsolation: true
        // },
        prefetch: false,
        async fetch(url, ...args) {
            if (loadingInstance === null) {
                loadingInstance = Loading.service({ text: '应用加载中...' });
            }

            return window.fetch(url, { cache: 'no-cache' }, ...args);
        }
    });
};
