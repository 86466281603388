<template>
    <div class="container">
        <layout>
            <template>
                <transition name="move" mode="out-in">
                    <router-view />
                </transition>
            </template>
        </layout>
    </div>
</template>
<script>
import layout from '@/components/layout';

export default {
    name: 'container',
    data() {
        return {};
    },
    components: {
        layout
    },
    mounted() {}
};
</script>
<style lang="less">
.container {
    .el-dialog__header {
        border-bottom: 1px solid #ddd;
        margin: 0 20px;
        padding: 20px 0;
        .el-dialog__title {
            font-size: 16px;
        }
    }
    .el-dialog__body {
        padding: 20px;
    }
}
</style>
