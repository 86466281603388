let watermark = {};
// let num = 0;
let setWatermark = str => {
    let id = '333333.222222.111111';

    if (document.getElementById(id) !== null) {
        document.body.removeChild(document.getElementById(id));
    }

    //创建一个画布
    let can = document.createElement('canvas');
    //设置画布的长宽
    can.width = 480;
    can.height = 220;

    let cans = can.getContext('2d');
    //旋转角度
    cans.rotate((-15 * Math.PI) / 100);
    cans.font = '18px Vedana';
    //设置填充绘画的颜色、渐变或者模式
    cans.fillStyle = 'rgba(200, 200, 200, 0.20)';
    //设置文本内容的当前对齐方式
    cans.textAlign = 'left';
    //设置在绘制文本时使用的当前文本基线
    cans.textBaseline = 'Middle';
    //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
    // /*
    //   双重遍历，
    //   当 宽度小于页面宽度时，
    //   当 高度小于页面高度时，
    //   这里的宽高可以适当写大，目的是为了让水印文字铺满
    //  */
    let H = document.body.offsetHeight;
    let W = document.body.offsetWidth;
    for (let i = H * -1; i < W; i += 240) {
        for (let j = 0; j < H * 1.5; j += 240) {
            // if (num % 2 == 1) {
            // 填充文字，x 间距, y 间距
            cans.fillText(str, i, j);
            // } else {
            //     cans.fillText(str, i, j * 0.7);
            // }
            // num++;
        }
    }

    let div = document.createElement('div');
    div.id = id;
    div.style.pointerEvents = 'none';
    div.style.top = '20px';
    div.style.left = '0px';
    div.style.position = 'fixed';
    div.style.zIndex = '100000';
    div.style.width = document.documentElement.clientWidth + 'px';
    div.style.height = document.documentElement.clientHeight + 'px';
    div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
    document.body.appendChild(div);
    return id;
};

// 该方法只允许调用一次
watermark.set = str => {
    // str = '温明晶';
    let id = setWatermark(str);
    setInterval(() => {
        if (document.getElementById(id) === null) {
            id = setWatermark(str);
        }
    }, 500);
    window.onresize = () => {
        setWatermark(str);
    };
};

export default watermark;
