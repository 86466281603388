<template>
    <div class="input-box">
        <input
            v-for="(item, index) in 4"
            v-model.number="code[index]"
            type="text"
            maxlength="1"
            :key="index"
            class="single-number-input"
            @keyup="keyupFun($event, index)"
        />
        <!-- @input="inputFun($event, index)" -->
        <!-- onkeyup="value=value.replace(/[^\d]/g,'')" -->
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    name: 'cashier',
    data() {
        return {
            code: this.value.split('')
        };
    },
    watch: {
        value(newVal) {
            this.code = newVal.split('');
        },
        code(newVal) {
            this.$emit('input', newVal.join(''));
        }
    },
    methods: {
        //按键按下监听
        keyupFun(e, p) {
            //如果在当前输入框输入值，则默认帮它放在下一个值去
            if (e.key === 'Backspace' && p > -1) {
                if (!this.code[p] && this.code[p] != '') {
                    p = p - 1 > 0 ? p - 1 : 1;
                }
                e.target.value = '';
                this.inputFun(e, p);
            } else if (e.key && this.code[p] && this.code[p].length > 0 && p < 3) {
                e.target.value = e.key;
                this.inputFun(e, p + 1);
            } else {
                this.inputFun(e, p);
            }
        },
        //1.监听用的键入，每次键入都替换掉焦点输入框的值
        //2.输入框的值只能有一位
        inputFun(e, p) {
            console.log(e, p);
            let ele = e.target;
            let value = e.target.value.replace(/\W/g, '').slice(-1);
            ele.value = value;
            let siblingsNode = ele.parentNode.children;
            if (!value) {
                //删除
                this.$delete(this.code, p);
                //this.code[p] = '';
                if (p <= 0) {
                    //边界值
                    p = 0;
                }
                if (p < 4 || this.code[p + 1] == '') {
                    p - 1 > 0 || p - 1 == 0 ? siblingsNode[p - 1].focus() : '';
                } else {
                    siblingsNode[p].focus();
                }

                return false;
            }

            this.$set(this.code, p, value);
            if (p >= siblingsNode.length - 1) {
                //边界值
                p = siblingsNode.length - 1;
            }
            if (value && p < 3) {
                //上一个元素获得焦点
                siblingsNode[p + 1].focus();
            }
        }
    }
};
</script>
<style lang="less" scoped>
.input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
        width: 40px;
        background: none;
        border: none;
        border-bottom: 1px solid #d9d9d9;
        font-size: 18px;
        margin-right: 16px;
        text-align: center;
        outline: none;
        padding-bottom: 8px;
    }
}
</style>
