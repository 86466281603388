<template>
    <div class="head-oper">
        <!-- <head-message></head-message> -->
        <head-record @showRecording="$emit('showRecording')"></head-record>
        <head-msg></head-msg>
        <!-- <head-video></head-video> -->
        <head-knowledge></head-knowledge>
        <head-oper-full></head-oper-full>
        <el-dropdown
            class="user-name"
            trigger="hover"
            @command="handleCommand"
            @visible-change="visibleChange"
        >
            <span class="el-dropdown-link">
                <div class="user-avator">
                    <img src="@/assets/img/v2/pc_default_head.png" />
                    <div class="user-name">{{ userinfo.realName }}</div>
                    <i :class="iconClass"></i>
                </div>
            </span>
            <el-dropdown-menu :visible-arrow="false" slot="dropdown" class="head-drop-menu">
                <el-dropdown-item command="updatePwd">修改密码</el-dropdown-item>
                <el-dropdown-item divided command="updatePhone">修改手机号</el-dropdown-item>
                <el-dropdown-item divided command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import headOperFull from './headOperFull';
import headKnowledge from './headKnowledge';
import headMsg from './headMsg';
// import headVideo from './headVideo';
import headRecord from './headRecord';
export default {
    name: 'HeadOper',
    data() {
        return {
            value: '',
            visible: false
        };
    },
    components: {
        headOperFull,
        headKnowledge,
        // headVideo,
        headRecord,
        headMsg
    },
    computed: {
        ...mapState(['userinfo']),
        iconClass() {
            return ['el-icon--right', this.visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'];
        }
    },
    methods: {
        visibleChange(data) {
            this.visible = data;
        },
        handleCommand(command) {
            const self = this;
            if (command === 'loginOut') {
                //退出登陆
                // this.$confirm('确定要退出嘛', '确认提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'info'
                // }).then(() => {
                //     self.$emit('loginOut');
                // });
                self.$emit('loginOut');
            } else if (command === 'updatePwd') {
                self.$emit('updatePwd');
            } else {
                self.$emit('updatePhone');
            }
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.head-oper {
    position: absolute;
    top: 0;
    right: 20px;
    .user-name {
        .user-avator {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
                display: block;
                width: 30px;
                height: 30px;

                border-radius: 6px;
            }
            .user-name {
                color: #fff;
                margin-left: 8px;
                margin-right: 3px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
            i {
                color: rgba(255, 255, 255, 0.7);
                font-size: 14px;
            }
        }
    }
}
</style>
<style lang="less">
.head-oper {
    display: flex;
    align-items: center;
    .seach-input input {
        border-radius: 17px;
    }
}
</style>
