<template>
    <div>
        <el-form ref="form" :model="form" label-position="top" :rules.sync="formRules">
            <el-form-item label="旧密码" prop="password" v-if="!isReset">
                <pwd-input v-model="form.password" placeholder="请输入旧密码" />
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <pwd-input v-model.trim="form.newPassword" placeholder="6-20位数字、字母组合" />
            </el-form-item>
            <el-form-item label="确认新密码" prop="confirmPassword">
                <pwd-input v-model.trim="form.confirmPassword" placeholder="请再次输入新密码" />
            </el-form-item>
        </el-form>
        <el-button class="btn" :loading="loading" type="primary" @click="onSumbit">确定</el-button>
    </div>
</template>
<script>
import { encode } from 'base-64';
import { URLencode } from '@/utils';
import { verifyPassword, updatePassword, logout } from '@mdos/services/lib/api';
import pwdInput from './pwdInput';

export default {
    name: 'MdosPwdForm',
    props: {
        // 就否重置密码
        isReset: {
            type: Boolean,
            default: false
        },
        userinfo: Object
    },
    data() {
        //旧密码
        const password = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入旧密码'));
            } else {
                let param = {
                    password: encode(URLencode(this.form.password))
                };
                verifyPassword(param).then(res => {
                    if (res.data) {
                        callback();
                        return;
                    }
                    callback(new Error('请输入正确的旧密码'));
                });
            }
        };
        //校验密码
        const newPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入新密码'));
            } else {
                let re = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]){6,20}');
                if (re.test(value) === false) {
                    callback(new Error('请输入6-20位数字、字母组合'));
                    return;
                }
                if (!this.isReset) {
                    let param = {
                        password: encode(URLencode(this.form.newPassword))
                    };
                    verifyPassword(param).then(res => {
                        if (res.data) {
                            callback(new Error('新密码与旧密码重复'));
                            return;
                        }
                        callback();
                    });
                } else {
                    callback();
                }
            }
        };
        //确认新密码
        const confirmPassword = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入确认新密码'));
            } else {
                if (value.length < 6) {
                    callback(new Error('密码长度不少于6位'));
                    return;
                }
                let { confirmPassword, newPassword } = this.form;
                if (newPassword !== confirmPassword) {
                    callback(new Error('新密码与确认新密码不一致'));
                    return;
                }
                callback();
            }
        };
        return {
            form: {
                password: '',
                newPassword: '',
                confirmPassword: ''
            },

            //验证规则
            formRules: {
                password: [{ required: true, trigger: 'blur', validator: password }],
                newPassword: [{ required: true, trigger: 'blur', validator: newPassword }],
                confirmPassword: [{ required: true, trigger: 'blur', validator: confirmPassword }]
            },

            loading: false
        };
    },
    methods: {
        //点击提交
        onSumbit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let { newPassword, password } = this.form;
                    let param = '';
                    if (this.isReset) {
                        param = {
                            newPassword: encode(URLencode(newPassword))
                        };
                    } else {
                        param = {
                            newPassword: encode(URLencode(newPassword)),
                            password: encode(URLencode(password))
                        };
                    }
                    this.update(param);
                } else {
                    return false;
                }
            });
        },
        //修改密码
        update(param) {
            this.loading = true;
            updatePassword(param).then(res => {
                if (res.resultCode == 0) {
                    logout().then(() => {
                        window.location.href = '/login';
                        // this.$router.push('/login');
                        this.loading = false;
                    });
                } else {
                    this.$message.error(res.resultMsg);
                    this.loading = false;
                }
            });
        }
    },

    components: {
        pwdInput
    }
};
</script>
<style lang="less" scoped>
.btn {
    width: 100%;
    margin-top: 10px;
}
</style>
