<template>
    <div class="head-record" @click="clickEvent">
        <el-tooltip class="item" effect="dark" content="录屏反馈" placement="bottom">
            <i class="iconfont">&#xe69f;</i>
        </el-tooltip>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        clickEvent() {
            this.$emit('showRecording');
        }
    }
};
</script>

<style lang="less" scoped>
.head-record {
    cursor: pointer;
    .iconfont {
        font-size: 20px;
        color: #fff;
        margin-right: 33px;
        vertical-align: middle;
    }
}
</style>
