<template>
    <div class="auth-empty">
        <div class="auth-text">请联系管理员开通权限！</div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },

    mounted() {}
};
</script>

<style lang="less" scoped>
.auth-empty {
    position: absolute;
    top: 60px;
    width: 100%;
    .auth-text {
        margin-top: 100px;
        text-align: center;
    }
}
</style>
