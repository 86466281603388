<template>
    <div v-if="preVisible || dialogVisible">
        <div class="container" v-if="preVisible">
            <vue-draggable-resizable
                class-name="draggable"
                :x="draggableX"
                :y="draggableY"
                :w="draggableW"
                :h="draggableH"
                :z="9999"
                :lock-aspect-ratio="true"
                :parent="true"
                v-if="preVisible"
            >
                <div class="draggable-container"></div>
                <video
                    height="100%"
                    width="100%"
                    :src="videoLink"
                    controls="controls"
                    autoplay="autoplay"
                    ref="video"
                >
                    您的浏览器不支持 video 标签。
                </video>
                <div class="footer-btns">
                    <el-button @click="submit" type="primary" class="commit-btn"
                        >提交反馈</el-button
                    >
                    <el-button class="close-btn" @click="handleClose">关闭</el-button>
                </div>
            </vue-draggable-resizable>
        </div>
        <div v-if="dialogVisible">
            <mdos-dialog
                title="上传视频反馈"
                :visible.sync="dialogVisible"
                width="424px"
                cancelText=""
                confirmText=""
            >
                <div class="upload-progress">
                    <el-progress
                        :text-inside="true"
                        :stroke-width="14"
                        :percentage="percentage"
                    ></el-progress>
                    <p class="upload-tips">
                        {{ percentage == 100 ? '上传成功' : '录屏上传中，请稍后...' }}
                    </p>
                </div>

                <div class="footer-btns upload-btns">
                    <el-button
                        :class="['close-btn', { 'close-disabled': percentage == 100 }]"
                        @click="cancel"
                        :disabled="percentage == 100"
                        >取消</el-button
                    >
                    <el-button
                        type="primary"
                        @click="cancel"
                        :class="['commit-btn', { 'commit-disabled': percentage != 100 }]"
                        :disabled="percentage != 100"
                        >确定</el-button
                    >
                </div>
            </mdos-dialog>
        </div>
    </div>
</template>

<script>
import VueDraggableResizable from '@mdos/vue-draggable-resizable';
import { getToken, reportVideoAdd } from '@/services';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import axios from '@mdos/axios';
export default {
    data() {
        return {
            dialogVisible: false,
            percentage: 0,
            videoUrl: '',
            duration: 0,
            source: null
        };
    },
    watch: {
        dialogVisible: function(newVal) {
            if (!newVal) {
                this.source.cancel();
            }
        }
    },
    methods: {
        ...mapActions(['setState']),
        handleClose() {
            this.$emit('update:preVisible', false);
        },
        submit() {
            this.handleClose();
            this.setState({
                showRecording: false
            });
            const duration = this.$refs.video.duration * 1000;
            if (duration == Infinity) {
                //为获取到视频时长
                this.duration = this.videoLong;
            } else {
                this.duration = duration;
            }
            this.percentage = 0;
            this.dialogVisible = true;
            this.downloadVideo();
            this.uploadVideo();
        },
        //下载视频到本地
        downloadVideo() {
            var eleLink = document.createElement('a');
            let name = `${this.userinfo.userName}(${moment().format('YYYY-MM-DD HH:mm:ss')})`;
            let binaryData = [];
            binaryData.push(this.webmData);
            let blob = new Blob(binaryData, { type: 'video/mp4' });
            eleLink.download = `${name}.mp4`;
            eleLink.href = URL.createObjectURL(blob);
            document.body.appendChild(eleLink);
            eleLink.click();
            document.body.removeChild(eleLink);
        },
        //上传视频
        async uploadVideo() {
            let that = this;
            const res = await getToken();
            var formdata = new FormData();
            let binaryData = [];
            binaryData.push(this.webmData);
            let blob = new Blob(binaryData, { type: 'video/mp4' });
            formdata.append('file', blob);
            formdata.append('token', res.data.token);
            let name = `${this.userinfo.userName}(${moment().format('YYYY-MM-DD HH:mm:ss')})`;
            const key = `${name}.mp4`;
            formdata.append('key', key);
            this.source = axios.CancelToken.source();
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                cancelToken: this.source.token,
                onUploadProgress(e) {
                    if (e.lengthComputable) {
                        let percentage = ((e.loaded / e.total) * 100) | 0;
                        that.percentage = percentage > 99 ? 99 : percentage;
                    }
                }
            };
            axios
                .post('https://up-z2.qbox.me/', formdata, config)
                .then(uploadRes => {
                    if (uploadRes.status == 200) {
                        let url = res.data.url + '/' + uploadRes.data.key;
                        this.videoUrl = url;
                        this.commitFeedback();
                    }
                })
                .catch(error => {
                    console.error('[ajax] ', '服务出错\n\t error path: \n', location.href, error);
                });
        },
        //确定 - 新增反馈
        async commitFeedback() {
            let data = {
                employeeNo: this.userinfo.userName,
                realName: this.userinfo.realName,
                userId: this.userinfo.userId,
                videoLong: this.duration,
                videoUrl: this.videoUrl
            };
            const res = await reportVideoAdd(data);
            if (res.resultCode == 0) {
                this.$message.success('提交成功!');
                this.percentage = 100;
                // this.dialogVisible = false;
            }
        },
        cancel() {
            this.dialogVisible = false;
        }
    },
    computed: {
        ...mapState(['webmData']),
        ...mapState(['userinfo']),
        ...mapState(['videoLong']),
        ...mapState(['preVideoSrc']),
        draggableX() {
            return document.body.offsetWidth / 2 - this.draggableW / 2;
        },
        draggableY() {
            return document.body.offsetHeight / 2 - this.draggableH / 2;
        },
        draggableW() {
            return (document.body.offsetWidth / 100) * 40;
        },
        draggableH() {
            return (this.draggableW / 16) * 9;
        }
    },
    components: {
        VueDraggableResizable
    },
    props: {
        preVisible: {
            type: Boolean,
            default: true
        },
        videoLink: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="less" scoped>
.el-progress /deep/ .el-progress-bar__inner {
    background-color: #4876ff;
}
/deep/ .el-progress-bar__innerText {
    margin-top: -5px;
}
/deep/ .el-dialog__body {
    padding: 32px 0 15px 0;
}
.upload-tips {
    margin-top: 8px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    color: #8186a5;
    text-align: center;
}
.upload-progress {
    padding: 0 24px;
}
.footer-btns {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .el-button {
        height: 38px;
        line-height: 38px;
        padding: 0;
    }
    .commit-btn {
        padding: 0 16px;
        background: #4876ff;
    }
    .close-btn {
        padding: 0 16px;
        margin-left: 16px;
    }
}
.upload-btns {
    margin-top: 32px;
    border-top: 1px solid #ebeef5;
    justify-content: flex-end;
    padding-top: 15px;
    padding-right: 24px;
    .el-button {
        height: 32px;
        line-height: 32px;
    }
    .close-disabled {
        border: 1px solid #dcdfe6;
        color: #b6bad2;
    }
    .commit-disabled {
        background: #c2d1ff;
        color: #ffffff;
        border: none;
    }
}
.container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #1a1c2980;
    z-index: 999;
}
.draggable {
    position: absolute;
    top: 0;
    left: 0;
    cursor: move;
    background-color: #ffffff;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .draggable-container {
        width: 100%;
        height: 80%;
        position: absolute;
        z-index: 99999;
    }

    /deep/ .handle.handle-br {
        box-sizing: border-box;
        position: absolute;
        width: 10px;
        height: 10px;
        cursor: nw-resize;
        bottom: -10px;
        right: -10px;
    }
}
</style>
