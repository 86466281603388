<template>
    <el-container class="app-main">
        <el-main class="main-container" :style="mainStyle">
            <el-scrollbar ref="scrollbar">
                <slot name="main"></slot>
            </el-scrollbar>
        </el-main>
    </el-container>
</template>
<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {};
    },
    components: {},
    computed: {
        ...mapState(['mainHeight']),
        mainStyle() {
            return {
                height: `${this.mainHeight}px`
            };
        }
    },

    methods: {}
};
</script>

<style lang="less" scoped>
.app-main {
    background-color: #f4f4f4;
    margin: 0 auto;
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
    /deep/ .el-scrollbar {
        height: 100%;
    }
    // .left-menu {
    //     border-right: 1px solid #e6e6e6;
    //     position: relative;
    //     right: 1px;
    // }
    .main-container {
        overflow: hidden;
        padding: 0;
    }
}
</style>
