<template>
    <div class="head-logo">
        <div class="head-logo-main" @click="clickEvent">
            <i class="iconfont logo-icon">&#xe604;</i>
            <img src="@/assets/img/v2/logo.png" alt="" />
        </div>
        <div class="head-logo-name" v-if="name">{{ name }}</div>
    </div>
</template>

<script>
export default {
    name: 'HeadLogo',
    data() {
        return {
            visible: false
        };
    },
    props: {
        name: {
            type: String,
            default: null
        }
    },
    components: {},
    methods: {
        clickEvent() {
            this.$emit('onClick');
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.head-logo {
    width: 200px;
    position: relative;
    float: left;
    .head-logo-main {
        width: 150px;
        cursor: pointer;
    }
    .logo-icon {
        color: #fff;
        font-size: 18px;
        margin-right: 20px;
    }
    img {
        vertical-align: text-bottom;
    }
    .head-logo-name {
        width: 200px;
        position: absolute;
        top: 0;
        right: -166px;
        font-size: 16px;
        color: #fff;
        height: 20px;
        margin-top: 21px;
        padding-left: 22px;
        line-height: 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
    }
}
</style>
