<template>
    <el-submenu :index="data.id">
        <template slot="title">
            <i
                :class="['iconfont', { 'nav-icon-active': currMenuIndex === index }]"
                style="color: #666;"
                v-html="data.icon"
            ></i>
            <span class="title-style">{{ data.name }}</span>
        </template>
        <div
            v-for="(item, sIndex) in data.children"
            :key="item.id"
            :style="{ backgroundColor: collapse ? '' : '#F8F8F8' }"
        >
            <div v-if="item.children">
                <el-submenu class="ss-submenu" :index="item.id">
                    <span slot="title" class="title-style" style="margin-left: 16px">{{
                        item.name
                    }}</span>
                    <el-menu-item
                        :index="citem.path"
                        v-for="citem in item.children"
                        :key="citem.id"
                        :class="[{ 'ss-submenu-item': !collapse }]"
                    >
                        <span class="title-style"> {{ citem.name }}</span>
                    </el-menu-item>
                </el-submenu>
            </div>
            <div v-else>
                <el-menu-item
                    :index="item.path"
                    :class="[
                        'ss-submenu',
                        {
                            'is-active': activeIndexs[1] === index && activeIndexs[2] === sIndex
                        }
                    ]"
                >
                    <span class="title-style">{{ item.name }}</span>
                </el-menu-item>
            </div>
        </div>
    </el-submenu>
</template>

<script>
export default {
    name: 'leftMenuSubItem',
    data() {
        return {};
    },
    props: {
        data: Object,
        currMenuIndex: Number,
        index: Number,
        activeIndexs: {
            type: Array,
            default: () => []
        },
        collapse: Boolean
    },
    components: {},
    methods: {},
    mounted() {}
};
</script>

<style lang="less">
.nav-icon-active {
    color: #099cf7 !important;
}

.title-style {
    margin-left: 12px;
}
.ss-submenu {
    .ss-submenu-item {
        background-color: #f8f8f8;
    }
    &.is-active:not(.is-opened) {
        background-color: #e7f3fd;
    }
    &.is-opened {
        .el-submenu__title {
            color: #999999;
        }
    }
    &.el-menu-item,
    .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }
    .el-menu-item,
    .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
    }
    .el-menu-item {
        &.is-active {
            background-color: #e7f3fd;
        }
    }
}
</style>
