<template>
    <div class="head-nav" ref="head-nav">
        <div v-if="isHide">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="我的应用" name="myapp"></el-tab-pane>
                <el-tab-pane label="应 用 库" name="applib"></el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeadNav',
    data() {
        return {
            activeName: 'myapp',
            itemList: {
                myapp: '/workCenter/myAppList',
                applib: '/workCenter/appListCenter/service'
            },
            isHide: true
        };
    },
    methods: {
        handleClick(tab) {
            const name = tab.name;
            let path = this.itemList[name];
            if (this.$route.path !== path) {
                this.$router.push(path);
            }
        }
    },
    watch: {
        '$route.path': {
            handler(path) {
                this.isHide = true;
                if (['/workCenter/myAppList', '/workCenter/editCard'].includes(path)) {
                    this.activeName = 'myapp';
                    this.$emit('onClick', 0);
                } else if (path === '/workCenter/appListCenter/service') {
                    this.activeName = 'applib';
                    this.$emit('onClick', 1);
                } else {
                    this.isHide = false;
                }
            },
            immediate: true
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.head-nav {
    width: 1248px;
    margin: 0 auto;
    /deep/ .el-tabs__item {
        padding: 0;
        color: rgba(255, 255, 255, 0.7);
        margin: 0 20px;
        &:hover {
            color: #fff;
        }
    }
    /deep/ .el-tabs__nav-wrap::after {
        height: 0;
    }
    /deep/ .el-tabs__item.is-active {
        color: #fff;
        background: url('~@/assets/img/v2/pc_head_hover.png') no-repeat center bottom;
    }
    /deep/ .el-tabs__active-bar {
        height: 0;
    }
}
@media screen and (max-width: 1280px) {
    .head-nav {
        width: 900px;
    }
}
</style>
