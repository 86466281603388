<template>
    <div class="pwd-change" :style="{ height: currHeight }">
        <div class="pwd-change-main">
            <div class="title">设置登录密码</div>
            <div class="name">
                {{ userinfo.realName }}
            </div>
            <mdos-pwd-form isReset />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import MdosPwdForm from '@/components/pwdForm';
export default {
    computed: {
        ...mapState(['userinfo']),
        currHeight() {
            return `${document.body.offsetHeight}px`;
        }
    },
    components: {
        MdosPwdForm
    },
    methods: {},
    mounted() {}
};
</script>
<style lang="less" scoped>
.pwd-change {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: url('~@/assets/img/pass-bg.png') no-repeat;
    background-size: cover;
    .pwd-change-main {
        width: 358px;
        padding: 32px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        background: #fff;
        border-radius: 2px;
        .title {
            font-size: 16px;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
        }
        .name {
            padding-bottom: 20px;
        }
    }
}
</style>
