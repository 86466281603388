<template>
    <div class="login" ref="login">
        <div class="login-bg">
            <div class="login-content">
                <div class="login-logo">
                    <img src="./img/logo_v2.png" alt />
                </div>
                <div class="login-center" v-if="step === 1">
                    <div class="login-center-title">欢迎使用</div>
                    <el-form
                        ref="form"
                        label-position="top"
                        class="login-message"
                        :model="form"
                        label-width="40px"
                    >
                        <el-form-item>
                            <el-input
                                v-model.trim="form.mobile"
                                type="text"
                                maxlength="13"
                                @keyup.enter.native="login"
                                @input="phoneInput"
                                placeholder="请输入手机号"
                            >
                                <template #prepend>
                                    <label><i class="el-icon-mobile-phone"></i> +86 </label>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div class="login-message">
                        <el-button
                            :class="[
                                'login-submit',
                                this.form.mobile.length < 13 || !timeClick ? 'none-click' : ''
                            ]"
                            type="primary"
                            :disabled="!timeClick"
                            @click="onBack(2)"
                        >
                            {{ timeNum === '重新获取' ? '获取验证码' : timeNum }}
                        </el-button>
                    </div>
                    <p class="phone-login" @click="onPhone">账号密码登录</p>
                </div>
                <!-- 验证码 -->
                <div class="login-center" v-else v-loading="loading">
                    <img
                        src="~@/assets/img/ic_login_back@2x.png"
                        class="back-icon"
                        @click="onBack(1)"
                    />
                    <div class="login-center-title mb8">请输入4位验证码</div>
                    <p class="remind">验证码会发送至+86{{ form.mobile }}</p>
                    <div class="phone-code">
                        <phone-code @input="getCode"></phone-code>
                    </div>
                    <div>
                        <p
                            :class="timeClick ? 'reset-word' : 'code-word'"
                            @click="timeClick && onBack(step)"
                        >
                            {{ timeNum }}
                        </p>
                    </div>
                </div>
            </div>
            <particles></particles>
        </div>
    </div>
</template>

<script>
import { formatPhone, regExpText, loginEvent } from '@/utils';
import Particles from './particles';
import PhoneCode from './phoneCode';
import { getVerifyCode } from '@/services';

export default {
    name: 'MdosLogin',
    data: () => {
        return {
            loading: false,
            form: {
                mobile: ''
            },
            step: 1, //步骤
            timer: null,
            timeNum: '重新获取',
            timeClick: true,
            checkCodeUniqueId: '' //验证码唯一码
        };
    },
    computed: {},
    components: {
        Particles,
        PhoneCode
    },
    methods: {
        //跳转到登录页面
        onPhone() {
            this.$router.push({ path: '/login' });
        },
        //点击跳转步骤
        async onBack(step) {
            if (step === 1) {
                this.timeStampFunc();
                this.step = step;
            }
            if (step === 2 && this.form.mobile.length < 13) return;
            if (step === 2) {
                /*  let obj = window.localStorage.getItem('mobileInfo');
                if (obj != null && obj != undefined) {
                    if (JSON.parse(obj).mobile == this.form.mobile) {
                        //同一个手机号，倒计时判断
                        let a = window.localStorage.getItem('currentNum');
                        if (a != 0 && a != null && a != undefined) {
                            //还在倒计时
                            this.step = step;
                            this.$message.success('验证码已发送，且在有效期内!');
                            return;
                        }
                    }
                } */
                let tel = this.form.mobile.replace(/\s/g, '');
                let bool = regExpText(tel, 'mobile');
                if (tel == '' || tel.length < 11 || !bool) {
                    this.$message.error('请填写正确的手机号码！');
                    return;
                }
                this.timeClick = true;
                clearInterval(this.timer);
                const res = await getVerifyCode({
                    mobile: tel
                });
                if (res.resultCode === '0') {
                    this.$message.success('验证码已发送，请注意查收！');
                    window.localStorage['currentNum'] = null;
                    window.localStorage['mobileInfo'] = JSON.stringify({
                        checkCodeUniqueId: res.data,
                        mobile: this.form.mobile
                    });
                    this.checkCodeUniqueId = res.data;
                    this.countNumber(59);
                    this.step = step;
                }
            }
        },
        //获取验证码
        async getCode(e) {
            if (e.length == 4) {
                let params = {
                    phone: this.form.mobile.replace(/\s/g, ''),
                    loginType: 3,
                    checkCode: e,
                    checkCodeUniqueId: this.checkCodeUniqueId,
                    loginSource: 2,
                    loginEquipment: navigator.userAgent // 用户登录的浏览器的基础信息
                };
                loginEvent(this, params);
            }
        },
        //倒计时
        countNumber(num) {
            this.timeClick = false;
            this.timer = null;
            this.timeNum = `${num}s后重新获取`;
            this.timer = setInterval(() => {
                num--;
                window.localStorage['currentNum'] = num;
                this.timeNum = `${num}s后重新获取`;
                if (num <= 0) {
                    this.timeClick = true;
                    clearInterval(this.timer);
                    this.timeNum = `重新获取`;
                }
            }, 1000);
        },
        phoneInput() {
            this.form.mobile = formatPhone(this.form.mobile);
        },
        // 获取离开的时间戳
        timeStampFunc() {
            if (!this.timeClick) {
                window.localStorage['timeStamp'] = new Date().getTime();
            }
        }
    },
    mounted() {
        this.$refs['login'].style.height = `${document.body.offsetHeight}px`;
        /*   let time = window.localStorage.getItem('timeStamp'); //假如切换时间5s
        if (time === null) return;
        let num = window.localStorage.getItem('currentNum'); // 假如缓存时间是48s
        let timeStamp = new Date().getTime() - time;
        let currentSecond = parseInt((num * 1000 - timeStamp) / 1000);
        if (currentSecond > 0) {
            //再进来不超过一分钟
            if (num !== null && num != undefined) {
                //继续执行原来的秒数
                let mobileInfo = JSON.parse(window.localStorage.getItem('mobileInfo'));
                this.form.mobile = mobileInfo.mobile;
                this.checkCodeUniqueId = mobileInfo.checkCodeUniqueId;
                this.countNumber(currentSecond);
            }
        } */
        let num = window.localStorage.getItem('currentNum');

        if (num != null && num != 'null' && num != undefined) {
            this.countNumber(num);
        }
    },
    beforeDestroy() {
        window.localStorage.removeItem('currentNum');
        this.timeNum = '重新获取';
        clearInterval(this.timer);
        this.timeStampFunc();
    }
};
</script>
<style lang="less" scoped>
#particles {
    top: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.login {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(180deg, #f9fbff 0%, #f2f7ff 100%);
}
.login-bg {
    height: 100%;
    width: 100%;
    background: url('./img/bg_login_botleft.png') no-repeat bottom left,
        url('./img/bg_login_botright.png') no-repeat bottom right;
    background-size: 40%;
}
.login-logo {
    position: absolute;
    top: -122px;
    left: 46px;
    img {
        width: 360px;
    }
}
.logo {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #999;
    position: relative;
    left: 80px;
    top: 16px;
    img {
        width: 28px;
        height: 28px;
    }
}
.login-content {
    width: 460px;
    height: 336px;
    background: #fff;
    margin: 0 auto; /*水平居中*/
    position: absolute;
    top: 50%; /*偏移*/
    left: 50%;
    z-index: 1999;
    margin-top: -200px;
    margin-left: -230px;
    // transform: translate(calc(50% - 460px), calc(50% - 400px));
    box-shadow: 0px 6px 24px 4px rgba(56, 162, 246, 0.2);
    box-sizing: border-box;
    padding: 50px 42px;
}
.login-center {
    position: relative;
    background: #fff;
    .back-icon {
        position: absolute;
        left: 0;
        top: 14px;
        height: 32px;
        width: 32px;
        cursor: pointer;
    }
    /deep/ .el-input {
        height: 32px;
        border-radius: 1px;
        .el-input__inner {
            border: 1px solid rgba(224, 224, 224, 1);
            color: #666660 !important;
            font-size: 14px;
            height: 46px;
            background: #fff;
        }
    }
    /deep/ .el-form-item {
        margin-bottom: 36px;
    }
    /deep/ .el-input--prefix .el-input__inner {
        padding-left: 42px;
    }
    /deep/ .el-input__prefix {
        top: 3px;
        left: 12px;
        i {
            font-size: 16px;
            color: #1089e9;
        }
    }
}
.login-center-title {
    text-align: center;
    letter-spacing: 4px;
    font-size: 26px;
    margin-bottom: 40px;
    line-height: 36px;
    color: #333;
    &.mb8 {
        margin-bottom: 8px;
    }
}
.remind {
    font-size: 14px;
    color: #999999;
    line-height: 14px;
    text-align: center;
}
.login-message {
    margin: 0 auto;
}
.iconfont {
    margin-top: 4px;
    margin-right: 10px;
    display: block;
    cursor: pointer;
}
.login-submit {
    width: 100%;
    margin-top: 10px;
    border-radius: 3px;
    height: 46px;
    &:hover {
        opacity: 0.8;
    }
    /deep/ span {
        color: rgba(255, 255, 255, 1);

        font-size: 14px;
        letter-spacing: 6px;
    }
    &.none-click {
        background: #d0d9e1;
        color: #fff;
        border: 1px solid #d0d9e1;
    }
}
.phone-login {
    font-size: 14px;
    color: #0d93f1;
    line-height: 14px;
    text-align: right;
    margin-top: 40px;
    cursor: pointer;
}
.phone-code {
    display: flex;
    justify-content: center;
    margin: 40px 0 64px;
}
.reset-word {
    font-size: 14px;
    font-weight: 400;
    color: #0d93f1;
    line-height: 14px;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}
.code-word {
    font-size: 14px;
    color: #a3a3a3;
    line-height: 14px;
    text-align: center;
}
/deep/ .el-input-group__prepend {
    background: none;
    font-size: 16px;
    color: #099cf7;
    line-height: 16px;
    border-right: none;
}
/deep/ .el-input .el-input__inner {
    border-left: none !important;
}
@media screen and (max-width: 1280px) {
    .login-content {
        width: 420px;
        height: 380px;
        margin-top: -160px;
        margin-left: -210px;
        // transform: translate(calc(50% - 420px), calc(50% - 360px));
    }
    .login-logo {
        top: -96px;
        left: 64px;
        img {
            width: 300px;
        }
    }
    .login-center-title {
        letter-spacing: 4px;
        font-size: 24px;
        line-height: 32px;
    }
}
</style>
