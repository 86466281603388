<template>
    <el-menu-item
        :class="['first-menu', { 'is-active': activeIndexs[1] === index }]"
        :index="data.path"
    >
        <i class="iconfont" style="color: #666; margin-right:12px" v-html="data.icon"></i>
        <span slot="title">{{ data.name }}</span>
    </el-menu-item>
</template>

<script>
export default {
    name: 'leftMenuItem',
    data() {
        return {};
    },
    props: {
        data: Object,
        index: Number,
        activeIndexs: {
            type: Array,
            default: () => []
        }
    },

    methods: {},
    mounted() {}
};
</script>

<style lang="less">
.first-menu {
    padding-left: 16px !important;
    .el-tooltip {
        padding-left: 16px !important;
    }
}
</style>
