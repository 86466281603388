<template>
    <div class="app-detail" :style="mainStyle">
        <div class="app-detail-header">
            <div class="app-detail-header-main">
                <mdos-page-header :propsData="headerData" :headerKey="headerKey"></mdos-page-header>
            </div>
        </div>
        <div class="main">
            <!-- <span class="iconfont back-icon" @click="$router.go(-1)">&#xe62f;</span> -->
            <!-- 头部 -->
            <div class="header">
                <div class="header-left">
                    <img :src="detail.icon" class="logo" />
                    <div>
                        <p class="name">{{ detail.name }}</p>
                        <div class="des">
                            <span><i class="iconfont">&#xe606;</i>{{ detail.typeDesc }}</span>
                            <span><i class="iconfont">&#xe608;</i>更新时间：{{ createTime }}</span>
                            <span>{{ detail.subscribeCount || 0 }}人在使用</span>
                        </div>
                    </div>
                </div>
                <div class="header-right">
                    <div class="warn" v-if="$route.query.hasAuthorization == 0">
                        <i class="iconfont">&#xe64b;</i>未获得授权，若需使用请联系系统管理员
                    </div>
                    <!-- 立即订阅、取消订阅的按钮，首先要是terminalType === 1（pc），然后取消订阅，已授权、未授权情况都可以取消订阅，立即订阅要在已授权情况下 -->
                    <div v-if="detail.terminalType === 1">
                        <!-- <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="small"
                            class="subscribe-btn"
                            v-if="detail.subscribe == 0 && $route.query.hasAuthorization == 1"
                            @click="handleSubscribe"
                            >立即订阅</el-button
                        > -->
                        <el-button
                            size="small"
                            type="primary"
                            @click.stop="openApp(detail)"
                            v-if="$route.query.hasAuthorization == 1"
                            >打开应用</el-button
                        >
                        <!-- <el-button
                            size="small"
                            class="subscribe-btn"
                            @click="cancelSubscribe"
                            v-if="detail.subscribe == 1"
                            >取消订阅</el-button
                        > -->
                    </div>
                    <div v-if="detail.terminalType === 2">
                        <!-- <el-popover
                            placement="top-start"
                            width="100"
                            trigger="click"
                            @show="creatQrCode"
                        >
                            <div id="androidQrcode">
                                <img :src="androidImgSrc" />
                                <div>请扫描二维码下载</div>
                            </div>
                            <el-button type="primary" slot="reference" plain size="medium"
                                ><i class="iconfont">&#xe66a;</i> <span>Android</span></el-button
                            >
                        </el-popover> -->
                        <el-popover
                            placement="top-start"
                            width="100"
                            trigger="click"
                            @show="creatQrCode"
                        >
                            <div id="iosQrcode">
                                <img :src="iosImgSrc" />
                                <!-- <div>请用系统摄像头或浏览器进行扫描下载</div> -->
                                <div>请扫描二维码下载</div>
                            </div>
                            <el-button
                                style="margin-left:20px"
                                type="primary"
                                slot="reference"
                                plain
                                size="medium"
                                ><i class="el-icon-mobile"></i> <span>下载安装</span></el-button
                            >
                        </el-popover>
                    </div>
                </div>
            </div>
            <!-- 应用内容 -->
            <div class="app-content">
                <div class="list">
                    <p class="title">应用介绍</p>
                    <div
                        class="introduce"
                        v-if="detail.description && detail.description.length > 0"
                    >
                        {{ detail.description }}
                    </div>
                    <p class="none-data" v-else>暂无应用介绍</p>
                </div>
                <div class="list">
                    <p class="title">应用截图</p>
                    <div>
                        <carousel
                            :list="detail.introduceImgUrls.split(',')"
                            v-if="detail.introduceImgUrls && detail.introduceImgUrls.length > 0"
                            @preview="onPreview"
                        ></carousel>
                        <p class="none-data" v-else>暂无截图</p>
                    </div>
                </div>
                <div class="list">
                    <p class="title">更新信息</p>
                    <div
                        v-html="detail.versionDesc"
                        v-if="detail.versionDesc && detail.versionDesc.length > 0"
                        :class="['version-contents', more ? 'more' : '']"
                        ref="versionContents"
                    ></div>
                    <p class="none-data" v-else>暂无更新信息</p>
                    <!-- <div :class="['version-contents', more ? 'more' : '']" ref="versionContents">
                        <div class="version-list" v-for="(item, index) in lists" :key="index">
                            <div>
                                <p class="sub-title">{{ item.version }}</p>
                                <div>
                                    <p
                                        class="version-content"
                                        v-for="(items, indexs) in item.list"
                                        :key="indexs"
                                    >
                                        {{ indexs + 1 }}、{{ items.content }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <p class="check-more" @click="checkMore" v-if="moreShow">
                        {{ more ? '点击收起' : '查看更多' }}<i class="el-icon-arrow-right"></i>
                    </p>
                </div>
                <div class="list">
                    <p class="title">相关应用</p>
                    <div class="application" v-if="application && application.length > 0">
                        <div
                            class="application-list"
                            v-for="(item, index) in application"
                            :key="index"
                            @click="onDetail(item.id, 1)"
                        >
                            <img :src="item.icon" class="logo" />
                            <div>
                                <p class="application-title">{{ item.name }}</p>
                                <p class="application-des">
                                    {{ item.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="none-data" v-else>暂无相关应用</p>
                </div>
            </div>
            <!-- <span class="go-top" @click="onTop" v-show="goTop">
                <i class="iconfont">&#xe64a;</i></span
            > -->
        </div>

        <preview-images ref="preview" v-show="previewShow"></preview-images>
        <!-- <el-backtop target=".app-detail" :visibility-height="100"></el-backtop> -->
        <el-backtop target=".app-detail" :visibility-height="100">
            <i class="iconfont">&#xe64a;</i>
        </el-backtop>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import carousel from './carousel';
import previewImages from './previewImages';
import { filterData, getQrcode } from '@/services';
import { applicationGet, cancelSubscribe, recommendList } from '@/services';
import moment from 'moment';
const formatDate = value => (value ? moment(value).format('YYYY-MM-DD') : '');
export default {
    components: { carousel, previewImages },
    data() {
        return {
            headerData: {
                breadCrumbList: [
                    {
                        name: '应用库',
                        path: '/workCenter/appListCenter/service'
                    },
                    { name: '' }
                ]
            },
            headerKey: Math.random(),
            goTop: false,
            androidImgSrc: '', // 安卓二维码路径
            iosImgSrc: '', // ios二维码路径
            //版本更新列表
            // lists: [
            //     {
            //         version: '版本V1.2.0',
            //         list: [
            //             {
            //                 content: '增加模块应用说明及版本说明，操作权限说明；'
            //             },
            //             {
            //                 content: '增加应用发布/上架逻辑（本版本发布即上架）；'
            //             }
            //         ]
            //     },
            //     {
            //         version: '版本V1.1.0',
            //         list: [
            //             {
            //                 content: '增加模块应用说明及版本说明，操作权限说明；'
            //             },
            //             {
            //                 content: '增加应用发布/上架逻辑（本版本发布即上架）；'
            //             }
            //         ]
            //     },
            //     {
            //         version: '版本V1.1.0',
            //         list: [
            //             {
            //                 content: '增加模块应用说明及版本说明，操作权限说明；'
            //             },
            //             {
            //                 content: '增加应用发布/上架逻辑（本版本发布即上架）；'
            //             }
            //         ]
            //     }
            // ],
            //相关应用列表
            application: [],
            timer: '', //定时器
            detail: '', //app详情
            more: true, //查看更多
            moreShow: false,
            previewShow: false
        };
    },
    computed: {
        ...mapState(['mainHeight', 'appInfo']),
        //格式化时间
        createTime() {
            return formatDate(this.detail.updateTime);
        },
        mainStyle() {
            return {
                height: `${this.mainHeight}px`
            };
        }
    },
    methods: {
        ...mapActions(['setState', 'appSubscribe', 'getUsersAccess']),
        //监听滚动高度
        scrollToTop() {
            let scrollTop =
                window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop > 0) {
                this.goTop = true;
            } else {
                this.goTop = false;
            }
        },
        onTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        onDetail(id, hasAuthorization) {
            //hasAuthorization 是否授权(0-未授权、1-已授权)
            this.$router.push({
                path: '/workCenter/appDetail',
                query: { id, hasAuthorization }
            });
        },
        async openApp(item) {
            filterData('app', {
                appUrl: item.hostName,
                appName: item.name,
                appId: item.id,
                menuName: '',
                menuPath: '',
                menuId: ''
            });
            const hostName = item.hostName;

            if (hostName) {
                if (hostName.includes('http://') || hostName.includes('http://')) {
                    window.location.href = hostName;
                } else {
                    this.appInfo[hostName] = this.data;
                    this.setState({
                        appInfo: this.appInfo
                    });
                    const res = await this.getUsersAccess({ applicationId: item.id, hostName });
                    if (res.resultCode === '0') {
                        //this.$router.push(hostName);
                        window.location.href = hostName;
                    }
                }
            } else {
                this.$message.error('应用暂时无法使用！');
            }
        },
        //详情
        async applicationGet() {
            try {
                let params = {
                    id: this.$route.query.id
                };
                let res = await applicationGet(params);
                if (res.resultCode === '0') {
                    this.detail = res.data;
                    this.headerData.breadCrumbList[1].name = this.detail.name;
                    this.headerKey = Math.random();
                    this.recommend();
                    this.$nextTick(() => {
                        if (this.$refs.versionContents) {
                            let h = this.$refs.versionContents.offsetHeight;
                            console.log('h~~~' + h);
                            if (h > 200) {
                                this.moreShow = true;
                            }
                        }

                        this.more = false;
                    });
                } else {
                    this.$message({
                        showClose: true,
                        message: res.resultMsg,
                        type: 'error'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        //取消订阅
        async cancelSubscribe() {
            try {
                let params = {
                    id: this.$route.query.id
                };
                let res = await cancelSubscribe(params);
                if (res.resultCode === '0') {
                    this.applicationGet();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.resultMsg,
                        type: 'error'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        //推荐应用
        async recommend() {
            try {
                let params = {
                    applicationId: this.$route.query.id,
                    // applicationType: this.detail.applicationType,
                    pageNum: 1,
                    pageSize: 3
                };
                let res = await recommendList(params);
                if (res.resultCode === '0') {
                    this.application = res.data.list;
                } else {
                    this.$message({
                        showClose: true,
                        message: res.resultMsg,
                        type: 'error'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        //立即订阅
        handleSubscribe() {
            // this.loading = true;
            this.appSubscribe({
                id: this.detail.id
            })
                .then(() => {
                    this.applicationGet();
                    this.$message({
                        message: '成功订阅该应用',
                        type: 'success'
                    });
                })
                .catch(() => {
                    // this.loading = false;
                });
        },
        checkMore() {
            this.more = !this.more;
        },
        onPreview(item) {
            this.previewShow = true;
            this.$refs.preview.show(item.url, this.detail.introduceImgUrls.split(','));
        },

        creatQrCode() {
            // this.$refs.qrCodeUrl.innerHTML = ''; // 创建前先清空
            // new QRCode(this.$refs.qrCodeUrl, {
            //     text: 'xxxx',
            //     width: 150,
            //     height: 150,
            //     colorDark: '#000000',
            //     colorLight: '#ffffff',
            //     correctLevel: QRCode.CorrectLevel.H
            // });
            getQrcode({}).then(res => {
                if (res.resultCode === '0') {
                    this.androidImgSrc = 'data:image/gif;base64,' + res.data.android;
                    this.iosImgSrc = 'data:image/gif;base64,' + res.data.qrCodeH5Url;
                }
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('scroll', this.scrollToTop);
        });
    },
    created() {
        this.applicationGet();
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollToTop);
    },
    watch: {
        '$route.query.id'() {
            this.application = [];
            this.detail = ''; //app详情
            this.applicationGet();
        }
    }
};
</script>
<style lang="less" scoped>
/deep/.el-backtop {
    height: 45px;
    width: 45px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    i {
        font-size: 20px;
        color: #dcdfe6;
    }
}
.app-detail {
    overflow-x: hidden;
    /*滚动条样式*/
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
}

/deep/ .el-dialog {
    background: none;
    box-shadow: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px !important;
    width: 100%;
    .el-dialog__header {
        border: 0px;
        padding: 0;
    }
    .el-dialog__headerbtn {
        top: 38px;
        right: 36px;
        z-index: 10;
        .el-dialog__close {
            border: #000;
            border-radius: 50%;
            font-size: 14px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            background: rgba(0, 0, 0, 0.2);
            box-shadow: none;
            color: #fff;
        }
    }
    .el-dialog__body {
        width: 83%;
    }
}
.app-detail-header {
    background: #f8f9fc;
    box-shadow: 0px 1px 0px 0px #e8e8e8;
    .app-detail-header-main {
        width: 1242px;
        margin: 0 auto;
        position: relative;
        /deep/.header-bread-crumb-2 .c-bread-crumb {
            background: #f8f9fc;
            padding-left: 0px;
        }
    }
}
.main {
    width: 1242px;
    margin: 0 auto;
    .go-top {
        position: fixed;
        bottom: 64px;
        right: 10%;
        display: block;
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        cursor: pointer;
        i {
            font-size: 20px;
            color: #dcdfe6;
        }
    }
    .back-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        color: #999999;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        margin-top: 24px;
        cursor: pointer;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #dcdfe6;
        .warn {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-right: 24px;
            i {
                color: #c0c4cc;
                margin-right: 4px;
            }
        }
        .header-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .logo {
                height: 72px;
                width: 72px;
                margin-right: 24px;
            }
            .name {
                font-size: 24px;
                font-weight: 600;
                color: #333333;
                line-height: 36px;
            }
            .des {
                margin-top: 10px;
                span {
                    border-right: 1px solid #dcdfe6;
                    padding-right: 16px;
                    font-size: 14px;
                    color: #333333;
                    line-height: 22px;
                    margin-right: 16px;
                    &:last-child {
                        border-right: 0px;
                    }
                }
                i {
                    color: #999999;
                    margin-right: 4px;
                }
            }
        }
        .header-right {
            display: flex;
            // flex-direction: column;
            // align-items: flex-end;
            justify-content: flex-end;
            align-items: center;
            .el-button--mini {
                margin-left: 10px;
                position: relative;
                top: 4px;
                height: 40px;
                /deep/ img {
                    width: 24px;
                    height: 24px;
                    margin-right: 5px;
                }
                span {
                    position: relative;
                    top: -7px;
                    font-size: 14px;
                    color: #717171;
                }
            }
        }
    }
    .app-content {
        margin-top: 16px;
        .none-data {
            font-size: 14px;
            color: #999;
        }
        .version-contents {
            line-height: 20px !important;
            font-size: 14px !important;
            overflow: hidden;
            max-height: 200px;
            color: #666666;
            &.more {
                max-height: inherit;
            }
        }
        .list {
            margin-bottom: 24px;
            .title {
                font-size: 18px;
                font-weight: 600;
                color: #333333;
                line-height: 28px;
                margin-bottom: 14px;
            }
            .introduce {
                font-size: 14px;
                color: #666666;
                line-height: 22px;
                white-space: break-spaces;
            }
            .sub-title {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 24px;
                margin-bottom: 16px;
            }
            .version-content {
                font-size: 14px;
                color: #666666;
                line-height: 22px;
            }
            .version-list {
                margin-bottom: 24px;
            }
            .check-more {
                font-size: 14px;
                color: #0d93f1;
                line-height: 19px;
                padding: 18px 0 24px 0;
                border-bottom: 1px solid #dcdfe6;
                cursor: pointer;
                i {
                    margin-left: 4px;
                    font-size: 12px;
                    color: #1189e9;
                }
            }
        }
        .application {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 40px;
            .application-list {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 28px 24px;
                box-sizing: border-box;
                border-radius: 8px;
                border: 1px solid #e4e7ed;
                width: 33.33%;
                margin-right: 16px;
                cursor: pointer;
                &:last-child {
                    margin-right: 0px;
                }
                .logo {
                    height: 56px;
                    width: 56px;
                    min-width: 56px;
                    margin-right: 24px;
                }
                .application-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 16px;
                    margin-bottom: 8px;
                }
                .application-des {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 22px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}
#iosQrcode,
#androidQrcode {
    img {
        width: 150px;
        height: 150px;
    }
    div {
        line-height: 19px;
        height: 19px;
        text-align: center;
        position: relative;
        top: -13px;
    }
}
</style>
