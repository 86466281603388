<template>
    <el-input
        v-model.trim="pwdValue"
        :type="inputType"
        maxlength="20"
        v-bind="$attrs"
        @input="checkInput"
    >
        <i slot="suffix" class="iconfont" v-html="icon" @click="showPass"></i>
    </el-input>
</template>
<script>
export default {
    props: ['value'],
    data() {
        return {
            type: true,
            pwdValue: '',
            icon: '&#xe610;'
        };
    },
    methods: {
        checkInput(val) {
            const handleVal = val.replace(/[ ]/g, '').replace(/[\u4E00-\u9FA5]/g, '');
            this.pwdValue = handleVal;

            this.$emit('input', handleVal);
        },
        showPass() {
            if (this.type) {
                this.icon = '&#xe605;';
                this.type = false;
            } else {
                this.icon = '&#xe610;';
                this.type = true;
            }
        }
    },
    computed: {
        inputType() {
            return this.type ? 'password' : 'text';
        }
    }
};
</script>
<style lang="less" scoped></style>
