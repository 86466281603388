import {
    reqLogin,
    getApplicationList,
    getUsersInfo,
    logout,
    getUsersAccess,
    getMyApplicationList,
    applicationSubscribe
} from '@mdos/services/lib/api';

import { SET_STATE, INIT_STATE } from './mutation-types';
import { get } from 'lodash';
import { filterData, getApplicationId, unreadPc } from '@/services';

export default {
    // 用户登录
    async reqLogin({ commit }, params) {
        const res = await reqLogin(params);
        const token = get(res, 'data.token', null);
        const userId = get(res, 'data.userId', null);
        if (token && userId) {
            filterData(
                'login',
                {
                    appUrl: '',
                    appName: '',
                    appId: '',
                    menuName: '',
                    menuPath: '',
                    menuId: ''
                },
                userId
            );
            commit(SET_STATE, {
                token,
                userId
            });
            return Promise.resolve({ token, userId });
        } else {
            const resultMsg = get(res, 'resultMsg', '登录失败');

            return Promise.reject(resultMsg);
        }
    },

    // 退出
    async logOut({ commit }) {
        const res = await logout();
        const code = get(res, 'resultCode', null);
        if (code === '0') {
            filterData('logout', {
                appUrl: '',
                appName: '',
                appId: '',
                menuName: '',
                menuPath: '',
                menuId: ''
            });
            commit(INIT_STATE);
            window.location.href = '/login';
            return Promise.resolve('退出成功！');
        }
    },

    // 获取工作台应用
    async getApplicationList({ commit }, params) {
        const res = await getApplicationList(params);
        const appList = get(res, 'data.list', []);

        commit(SET_STATE, {
            appList
        });
        return Promise.resolve(res);
    },

    // 获取我的应用列表
    async getMyApplicationList({ commit }, params) {
        const res = await getMyApplicationList(params);
        let myAppList = get(res, 'data.list', []);

        myAppList = myAppList.filter(item => item.terminalType !== 2);
        commit(SET_STATE, {
            myAppList: myAppList ? myAppList : []
        });
        return Promise.resolve(res);
    },

    // 获取用户信息
    async getUsersInfo({ commit }) {
        const res = await getUsersInfo();
        const userinfo = get(res, 'data', {});

        commit(SET_STATE, {
            userinfo
        });
        return Promise.resolve(userinfo);
    },

    // 获取菜单
    async getUsersAccess({ commit, state }, { applicationId, hostName }) {
        const res = await getUsersAccess({ applicationId });

        let access = get(res, 'data', {});

        state.accessList[hostName] = access;

        commit(SET_STATE, {
            accessList: state.accessList,
            access
        });
        return Promise.resolve(res);
    },

    // 根据url获取应用详情
    async getAppInfo({ commit, state }, { hostName }) {
        const res = await getApplicationId({ hostName });
        const application = get(res, 'data', null);

        if (application.id === -1) return Promise.resolve(null);
        state.appInfo[hostName] = application;

        commit(SET_STATE, {
            appInfo: state.appInfo,
            currAppInfo: application
        });

        return Promise.resolve(application);
    },

    // 设置state数据
    setState({ commit }, params) {
        commit(SET_STATE, params);
    },

    // 订阅应用
    async appSubscribe({ commit, state }, params) {
        const res = await applicationSubscribe(params);
        const resultCode = get(res, 'resultCode', null);
        let appList = state.appList;

        if (resultCode === '0') {
            // 修改应用的subscribe字段
            appList = appList.map(item => ({
                ...item,
                subscribe: params.id === item.id ? 1 : item.item
            }));

            commit(SET_STATE, {
                appList
            });
            return Promise.resolve(res);
        } else {
            return Promise.reject(res);
        }
    },
    //获取信息推送条数
    async unreadPcTotal({ commit }) {
        let { resultCode, data } = await unreadPc();
        if (resultCode === '0') {
            let msgTotalUnread = data;
            commit(SET_STATE, {
                msgTotalUnread
            });
            return Promise.resolve(data);
        } else {
            return Promise.reject(data);
        }
    }
};
