/* eslint-disable */
// export const URLencode = (sStr) => {
//     return escape(sStr).replace(/\@/g, '%40').replace(/\+/g, '%2B').replace(/\//g, '%2F');
// };
export const URLencode = sStr => {
    return encodeURIComponent(sStr)
        .replace(/\~/g, '%7E')
        .replace(/\!/g, '%21')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\'/g, '%27');
};

export const transformUrl = (url, id) => {
    return url.replace(/\$\{id\}/g, id);
};

/**
* 验证正则
*/
export function regExpText(text, type, regEpx) {
    let pass = false;
    switch (type) {
        case 'mobile':
            pass = /^((13[0-9]|14[579]|15[0-3,5-9]|16[068]|17[0135678]|18[0-9]|19[189])+\d{8})$/.test(text);
            break;
        case 'password':
            pass = /^[a-zA-Z\d]{6,12}$/.test(text);
            break;
        default:
            pass = regEpx ? regEpx.test(text) : false;
            break;
    }
    return pass;
}
/*格式化手机号码344*/
export function formatPhone(mobile) {
    var value = mobile.replace(/\D/g, '').substring(0, 11);
    var valueLen = value.length;
    if (valueLen > 3 && valueLen < 8) {
        value = `${value.substr(0, 3)} ${value.substr(3)}`;
    } else if (valueLen >= 8) {
        value = `${value.substr(0, 3)} ${value.substr(3, 4)} ${value.substr(7)}`;
    } 
    return value;
}

/*格式化手机号码344*/
export function loginEvent(that, form) {
    that.loading = true;
    // 用户登录、获取用户信息
    that.$store
        .dispatch('reqLogin', form)
        .then(() => {
            that.$store.dispatch('getUsersInfo').then((res) => {
                setTimeout(() => {
                    window.localStorage['currentNum'] = null;
                    window.localStorage['timeStamp'] = null;
                    window.localStorage['mobileInfo'] = '{}';
                }, 1000)
                if (res.passwordStatus === 0) {
                    that.$emit('passwordChange');
                } else {
                    that.$router.push('/');
                }
                that.loading = false;
            });
        })
        .catch(() => {
            that.loading = false;
        });
}

export function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    } else {
        return null;
    }
 
}