<template>
    <div class="main" :style="conentHeight">
        <div class="main-left">
            <el-scrollbar>
                <ul>
                    <li
                        v-for="(item, index) in list"
                        :key="index"
                        :class="[{ read: item.status === 1 }, { active: item.active }]"
                        @click="handleClick(item)"
                    >
                        <div class="title">
                            {{ item.content }}
                        </div>
                        <div class="time">{{ item.createTime }}</div>
                    </li>
                </ul>
            </el-scrollbar>
        </div>
        <div class="main-right">
            <component
                :is="useComp"
                :imgSrc="imgSrc"
                :data="msgDetail"
                text="您还未选中任何消息进行查看！"
            ></component>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import contEmpty from './empty';
import msgContent from './content';

export default {
    data() {
        return {
            imgSrc: require('@/assets/img/message/read-empty.png')
        };
    },
    props: {
        status: String
    },

    computed: {
        ...mapGetters(['alreadyMsgList', 'unreadMsgList']),
        ...mapState({
            mainHeight: 'mainHeight',
            msgList: state => state.message.msgList,
            msgDetail: state => state.message.msgDetail
        }),
        conentHeight() {
            return {
                height: `${this.mainHeight - 32 - 32 - 56}px`
            };
        },
        useComp() {
            return this.msgDetail ? 'msgContent' : 'contEmpty';
        },
        list() {
            let data = this.msgList;

            if (this.status === 'unread') {
                data = this.unreadMsgList;
            } else if (this.status === 'already') {
                data = this.alreadyMsgList;
            }
            return data;
        }
    },
    components: {
        contEmpty,
        msgContent
    },
    methods: {
        ...mapActions(['setState', 'reqMsgNotice', 'setState', 'reqMsgRead']),
        handleClick(item) {
            let newMsgList = [];
            this.msgList.forEach(msg => {
                let obj = { ...msg, active: false };
                if (msg.id === item.id) {
                    obj.active = true;
                }
                newMsgList.push(obj);
            });

            this.reqMsgNotice({ noticeId: item.params.sourceId });

            if (item.status === 1) {
                this.reqMsgRead({
                    id: item.id
                });
            }

            this.setState({
                msgList: newMsgList,
                _deep: 'message'
            });
        }
    }
};
</script>

<style lang="less" scoped>
.main {
    display: flex;
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
    /deep/ .el-scrollbar {
        height: 100%;
    }
    .main-left {
        flex: 2;
        border-right: 1px solid #eee;

        ul {
            li {
                padding: 28px 28px 28px 50px;
                line-height: 24px;
                position: relative;
                cursor: pointer;
                &.active {
                    background-color: #fafafa;
                    .content {
                        color: #666;
                    }
                    .time {
                        color: #999;
                    }
                }
                &:hover {
                    .content {
                        color: #666;
                    }
                    .time {
                        color: #999;
                    }
                }

                &.read::before {
                    background: #ff4d4f;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 36px;
                    border-radius: 10px;
                    width: 10px;
                    background: #999999;
                    height: 10px;
                    left: 32px;
                }

                .title {
                    color: #999999;
                }
                .time {
                    margin-top: 8px;
                    color: #d2d2d2;
                }
            }
        }
    }
    .main-right {
        padding-right: 32px;
        flex: 3;
    }
}
</style>
