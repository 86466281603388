import 'whatwg-fetch';
import '@mdos/custom-event-polyfill';
import 'core-js/stable/promise';
import 'core-js/stable/symbol';
import 'core-js/stable/string/starts-with';
import 'core-js/web/url';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from '@mdos/element-ui';
import MdosCommon from '@mdos/common';
import MdosComponents from '@mdos/components-v2';
import vUtilsPkg from '@mdos/helpers';
import startQiankun from './micro';
import '@mdos/theme-v2/lib/index.css';
import '@mdos/theme-v2/lib/reset.css';
import '@mdos/components-v2/lib/theme/index.css';
import 'nprogress/nprogress.css';
// import '@/assets/styles/iconfont.css';
import '@/assets/iconfont/iconfont.css';
import '@/assets/styles/HarmonyOS.css';
import '@mdos/swiper/dist/css/swiper.css';
import VueAwesomeSwiper from '@mdos/vue-awesome-swiper';
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(vUtilsPkg);
Vue.use(MdosComponents);
MdosCommon(Vue, ElementUI);
startQiankun();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
