<template>
    <div class="main" :style="{ height: `${this.mainHeight}px` }">
        <div :style="{ height: `${this.mainHeight}px` }" class="main-left">
            <el-scrollbar>
                <left-menu></left-menu>
            </el-scrollbar>
        </div>
        <div :class="['main-container', { 'main-container-bread': !isShow && isShowBrea }]">
            <mdos-page-header
                :propsData="headerData"
                :key="random"
                v-if="isShow"
            ></mdos-page-header>
            <div class="breadcrumb" v-if="!isShow && isShowBrea && (listName || childName.name)">
                <div v-if="!childName.name">{{ listName }}</div>
                <div v-else>
                    <span class="det" @click="goBlack"
                        ><i class="el-icon-arrow-left"></i> {{ childName.name }}</span
                    >
                </div>
            </div>
            <el-scrollbar ref="scrollbar">
                <div
                    :class="[
                        'main-container-scrollbar',
                        { 'breadcrumb-style': !isShow && isShowBrea }
                    ]"
                    :style="{
                        height: `${
                            !isShow && isShowBrea && (listName || childName.name)
                                ? this.mainHeight - 80
                                : this.mainHeight - 40
                        }px`
                    }"
                >
                    <slot></slot>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getBreadcrumb } from '@/services';

import LeftMenu from './leftMenu';

export default {
    data() {
        return {
            headerData: {
                breadCrumbList: []
            },
            listName: '',
            childName: {},
            random: 1,
            hideCrumbs: []
        };
    },

    computed: {
        ...mapState(['mainHeight', 'currAppInfo']),
        ...mapGetters(['menu']),
        isShow() {
            let isShow = true;
            if (this.hideCrumbs.includes(this.currAppInfo.hostName)) {
                isShow = false;
            }

            return isShow;
        },
        isShowBrea() {
            const hideCrumbs = ['/microApp/ioc', '/microApp/equipEnergy', '/microApp/system'];
            let isShowBrea = true;

            if (hideCrumbs.includes(this.currAppInfo.hostName)) {
                isShowBrea = false;
            }

            return isShowBrea;
        }
    },
    components: {
        LeftMenu
    },
    methods: {
        // 遍历菜单获取当前菜单数据
        getCurrMenuData(data) {
            if (data) {
                const currPath = this.$route.path.replace(this.currAppInfo.hostName, '');
                const getMatchPathData = (routes, currPath, index = []) => {
                    for (let i = 0; i < routes.length; i++) {
                        const nRouters = {
                            name: routes[i].name,
                            routePath: routes[i].routePath
                        };
                        if (routes[i].routePath === currPath) {
                            if (index.length) {
                                return [...index, nRouters];
                            }
                            return [nRouters];
                        } else {
                            if (routes[i].children) {
                                let pathInfoIndex = getMatchPathData(routes[i].children, currPath, [
                                    ...index,
                                    nRouters
                                ]);

                                if (pathInfoIndex !== undefined) {
                                    return pathInfoIndex;
                                }
                            }
                        }
                    }
                };

                let matchPathData = getMatchPathData(data, currPath);

                if (matchPathData) {
                    matchPathData = matchPathData.map(item => {
                        const newItem = { name: item.name };

                        if (
                            item.routePath &&
                            this.currAppInfo.hostName + item.routePath !== this.$route.path
                        ) {
                            newItem.path = this.currAppInfo.hostName + item.routePath;
                        }
                        return newItem;
                    });
                }

                if (matchPathData) matchPathData.shift();

                this.headerData.breadCrumbList = matchPathData;
                this.listName = '';
                this.childName = {};
                if (matchPathData && matchPathData.length === 1) {
                    this.listName = matchPathData[0].name;
                } else if (matchPathData && matchPathData.length === 2) {
                    this.childName.name = matchPathData[1].name;
                    this.childName.path = matchPathData[0].path;
                }

                this.random = new Date().getTime();
            }
        },
        async getBreadcrumb() {
            const res = await getBreadcrumb({
                pageSize: 100
            });
            if (res.resultCode === '0') {
                this.hideCrumbs = res.data.list.map(item => `/microApp/${item.name}`);
            }
        },
        goBlack() {
            if (window.history.length) {
                window.history.go(-1);
            } else {
                this.$router.push(this.childName.path);
            }
        }
    },
    watch: {
        '$route.path': {
            handler() {
                this.$nextTick(() => {
                    this.$refs.scrollbar.wrap.scrollTop = 0;
                });

                this.getCurrMenuData(this.menu);
            },
            immediate: true
        }
    },
    mounted() {
        this.getBreadcrumb();
    }
};
</script>

<style lang="less" scoped>
.main {
    display: flex;
    .main-left {
        background-color: #fff;
        border-right: 1px solid #e8e8e8;
        box-sizing: border-box;
    }
    .main-container {
        // padding: 0 0 20px 0;
        padding-bottom: 40px;
        overflow: hidden;
        width: 100%;
        position: relative;
        .main-container-scrollbar {
            padding: 20px 24px;
        }
        .breadcrumb-style {
            padding: 0 24px 20px 24px;
        }
    }
    .main-container-bread {
        padding-bottom: 60px;
    }
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden !important;
    }
    /deep/ .el-scrollbar {
        height: 100%;
    }
    .breadcrumb {
        font-size: 16px;
        color: #202332;
        margin: 20px 24px;
        line-height: 20px;
        font-family: 'MicrosoftYaHei';
        .det {
            cursor: pointer;
        }
    }
}
</style>
