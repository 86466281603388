<template>
    <div
        :class="['app-item', { active }]"
        @mouseover="active = true"
        @mouseleave="visible ? (active = true) : (active = false)"
        @click.stop="clickEvent"
    >
        <div class="app-img">
            <img :src="data.icon" />
        </div>
        <div class="text">
            <div class="title">
                {{ data.name }}
            </div>
            <div class="remarks">
                {{ data.description }}
            </div>
        </div>
        <!--  <div class="more" @click.stop="() => {}">
            <el-dropdown size="small" @visible-change="visibleChange" @command="handleCommand">
                <span class="el-dropdown-link">
                    <i class="iconfont">&#xe60a;</i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="cancel">取消订阅</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div> -->
        <div class="no-auth" v-if="data.hasAuthorization === 0">
            <i class="iconfont" style="margin-right: 6px; vertical-align: bottom;">&#xe64b;</i>
            授权已失效，有疑问请联系系统管理员
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { cancelSubscribe, filterData } from '@/services';
export default {
    name: 'AppItem',
    data() {
        return {
            active: false,
            visible: false
        };
    },
    props: {
        data: {
            type: Object
        }
    },
    components: {},
    computed: {
        ...mapState(['appInfo'])
    },
    methods: {
        ...mapActions(['getMyApplicationList', 'getUsersAccess', 'setState']),
        async clickEvent() {
            if (this.data.hasAuthorization === 0) return;
            filterData('app', {
                appUrl: this.data.hostName,
                appName: this.data.name,
                appId: this.data.id,
                menuName: '',
                menuPath: '',
                menuId: ''
            });
            const hostName = this.data.hostName;

            if (hostName) {
                if (hostName.includes('http://') || hostName.includes('http://')) {
                    window.location.href = hostName;
                } else {
                    this.appInfo[hostName] = this.data;
                    this.setState({
                        appInfo: this.appInfo
                    });

                    const res = await this.getUsersAccess({
                        applicationId: this.data.id,
                        hostName
                    });

                    if (res.resultCode === '0') {
                        this.$router.push(hostName);
                    }
                }
            } else {
                // this.$message.closeAll();
                this.$message.error('应用暂时无法使用！');
            }
        },
        visibleChange(data) {
            this.active = data;
            this.visible = data;
        },
        async reqCancelSubscribe() {
            let res = await cancelSubscribe({
                id: this.data.id
            });

            if (res.resultCode === '0') {
                this.getMyApplicationList().then(() => {
                    this.$message({
                        message: '取消订阅成功！',
                        type: 'success'
                    });
                });
            }
        },
        handleCommand(command) {
            if (command === 'cancel') {
                this.reqCancelSubscribe();
            }
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.app-item {
    cursor: pointer;
    width: 300px;
    background: #ffffff;
    box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    position: relative;
    margin-right: 16px;
    margin-bottom: 16px;
    &.active,
    &:hover {
        box-shadow: 0px 9px 12px 2px rgba(68, 95, 121, 0.1);
        .more {
            display: block;
        }
    }
    .app-img {
        height: 56px;
        width: 56px;
        margin: 28px 24px;
        overflow: hidden;
    }
    img {
        width: 100%;
    }
    .text {
        margin-top: 24px;
        padding-right: 24px;
        overflow: hidden;
        flex: 1;
        .title {
            font-size: 16px;

            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .remarks {
            margin-top: 7px;
            color: #999;
            height: 44px;
            line-height: 22px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 14px;
        }
    }
    .more {
        position: absolute;
        right: 16px;
        top: 10px;
        color: #999999;
        display: none;
        i:hover {
            color: #2372ff;
        }
    }
    .no-auth {
        position: absolute;
        font-size: 14px;
        width: 100%;
        color: #fff;
        text-align: center;
        padding: 8px 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;

        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
}
@media screen and (max-width: 1280px) {
    .app-item {
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}
@media screen and (min-width: 1281px) {
    .app-item {
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}
</style>
