/* eslint-disable */
import config from '@mdos/config';

const {
    patrolHost,
    gmvHost,
    isdHost,
    recHost,
    systemHost,
    reportHost,
    parkinglotHost,
    posHost,
    detailsHost,
    receivableHost,
    messageHost,
    overviewRecHost,
    signatureHost,
    arrearsMarkHost,
    customerHost,
    equipEnergyHost,
    superappHost,
    iocHost,
    parkingContractHost,
    appletsHost,
    assetsHost,
    taskReportHost
} = config.env[config.currEnv];

const apps = [
    /**
     * name: 微应用名称 - 具有唯一性
     * entry: 微应用入口 - 通过该地址加载微应用
     * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
     * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
     */
    {
        name: 'PatrolMicroApp',
        entry: patrolHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/patrol'
    },
    {
        name: 'GmvMicroApp',
        entry: gmvHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/gmv'
    },
    {
        name: 'IsdMicroApp',
        entry: isdHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/isd'
    },
    {
        name: 'RecMicroApp',
        entry: recHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/rec'
    },
    {
        name: 'SystemMicroApp',
        entry: systemHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/system'
    },
    {
        name: 'ReportMicroApp',
        entry: reportHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/report'
    },
    {
        name: 'packingMicroApp',
        entry: parkinglotHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/packing'
    },
    {
        name: 'posMicroApp',
        entry: posHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/pos'
    },
    {
        name: 'detailMicroApp',
        entry: detailsHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/detailsrec'
    },
    {
        name: 'ReceivableMicroApp',
        entry: receivableHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/receivable'
    },
    {
        name: 'MessageMicroApp',
        entry: messageHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/message'
    },
    {
        name: 'SignatureMicroApp',
        entry: signatureHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/signature'
    },
    {
        name: 'ArrearsMarkMicroApp',
        entry: arrearsMarkHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/arrearsMark'
    },
    {
        name: 'OverviewMicroApp',
        entry: overviewRecHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/overviewRec'
    },
    {
        name: 'CustomerMicroApp',
        entry: customerHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/customer'
    },
    {
        name: 'EquipEnergyMicroApp',
        entry: equipEnergyHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/equipEnergy'
    },
    {
        name: 'SuperappMicroApp',
        entry: superappHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/superapp'
    },
    {
        name: 'IocMicroApp',
        entry: iocHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/ioc'
    },
    {
        name: 'ParkingContractMicroApp',
        entry: parkingContractHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/parkingContract'
    },
    {
        name: 'AppletsMicroApp',
        entry: appletsHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/applets'
    },
    {
        name: 'AssetsMicroApp',
        entry: assetsHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/assets'
    },
    {
        name: 'TaskReportMicroApp',
        entry: taskReportHost,
        container: '#subapp-viewport',
        activeRule: '/microApp/taskReport'
    }
];

export default apps;
