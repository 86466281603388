<template>
    <el-drawer
        :visible="visible"
        size="245px"
        @close="closeEvent"
        custom-class="left-drawer"
        direction="ltr"
        :modal-append-to-body="false"
        ref="left-drawer"
    >
        <div slot="title" class="drawer-title" @click="homeClickEvent">
            <i class="iconfont">&#xe60c;</i><span>首页</span>
        </div>
        <el-scrollbar class="m-scrollbar" style="height: 100%;">
            <slot></slot>
        </el-scrollbar>
    </el-drawer>
</template>

<script>
export default {
    name: 'LeftDrawer',
    data() {
        return {};
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeEvent() {
            this.$emit('update:visible', false);
            this.$emit('close', false);
        },
        homeClickEvent() {
            this.closeEvent();
            if (this.$route.path !== '/workCenter/myAppList') this.$router.push('/');
        }
    },
    watch: {
        visible(data) {
            // 修复el-drawer__bod无法完全滚动bug
            if (data) {
                this.$nextTick(() => {
                    try {
                        const drawerBody = this.$refs['left-drawer'].$el.querySelector(
                            '.el-drawer__body'
                        );
                        const menuItems = drawerBody
                            .querySelector('.el-menu-item-group')
                            .querySelector('ul').childNodes;
                        const num = menuItems.length;

                        drawerBody.style.heigth = `${48 * num}px`;
                        drawerBody.style.overflow = 'auto';
                    } catch (error) {
                        console.warn(error);
                    }
                });
            }
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.left-drawer {
    outline: none;
    .drawer-title {
        font-size: 16px;
        color: #999;
        cursor: pointer;
        &:hover {
            color: #5295e3;
        }
        i {
            margin-right: 5px;
        }
    }
}
/deep/ .el-drawer__header {
    margin-bottom: 20px;
}
/deep/ .el-drawer__close-btn {
    outline: none;
}
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden !important;
}
</style>
