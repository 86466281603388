<template>
    <el-dialog class="app-dialog" v-loading="loading" :visible.sync="isVisible">
        <div class="app-dialog-main">
            <div class="image">
                <img :src="data.icon" />
            </div>
            <div class="title-main">
                <div class="title">{{ data.name }}</div>
                <div class="info">
                    <span style="margin-right: 20px"
                        ><i class="iconfont" v-html="icon"></i>
                        {{ data.terminalTypeName }}端应用</span
                    >
                    <span
                        ><i class="iconfont">&#xe608;</i>
                        上线时间：{{ data.shelfTime | @formatDate('YYYY/MM/DD') }}</span
                    >
                </div>
            </div>
            <div class="btn" v-if="data.terminalType === 1">
                <el-button
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                    @click="subscribeEvent"
                    v-if="!data.subscribe"
                    >立即订阅</el-button
                >
                <div v-else>
                    <el-button size="small" type="primary" @click="openApp">打开应用</el-button>
                    <el-button size="small" @click="cancelEvent">取消订阅</el-button>
                </div>
            </div>
        </div>
        <div class="app-dialog-brief">
            <div class="title">应用介绍</div>
            <div class="brief">{{ data.brief }}</div>
        </div>
        <div class="app-dialog-banner">
            <mdos-carousel :data="banners"></mdos-carousel>
        </div>
        <div class="app-dialog-desc">
            {{ data.description }}
        </div>
    </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import { cancelSubscribe } from '@/services';
import MdosCarousel from './carousel';

export default {
    name: 'AppLibBlackDialog',
    data() {
        return {
            isVisible: false,
            loading: false
        };
    },
    props: {
        data: {
            type: Object
        },
        visible: {
            type: Boolean,
            defualt: false
        }
    },
    computed: {
        icon() {
            return this.data.terminalType === 1 ? '&#xe606;' : '&#xe612;';
        },
        banners() {
            return this.data.banner ? this.data.banner.split(',') : [];
        }
    },
    components: {
        MdosCarousel
    },
    watch: {
        visible: {
            handler(val) {
                this.isVisible = val;
            },
            immediate: true
        },
        isVisible(val) {
            this.$emit('update:visible', val);
        }
    },
    methods: {
        ...mapActions(['appSubscribe', 'getApplicationList']),
        async reqCancelSubscribe() {
            this.loading = true;
            let res = await cancelSubscribe({
                id: this.data.id
            });

            if (res.resultCode === '0') {
                this.loading = false;
                this.isVisible = false;
                this.getApplicationList({ pageSize: 1000 }).then(() => {
                    this.$message({
                        message: '应用已取消订阅！',
                        type: 'success'
                    });
                });
            }
        },
        subscribeEvent() {
            this.loading = true;
            this.appSubscribe({
                id: this.data.id
            })
                .then(() => {
                    this.loading = false;
                    this.isVisible = false;
                    this.getApplicationList({ pageSize: 1000 }).then(() => {
                        this.$message({
                            message: '成功订阅该应用',
                            type: 'success'
                        });
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        cancelEvent() {
            this.reqCancelSubscribe();
        },
        openApp() {
            this.$emit('open', this.data);
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.app-dialog {
    .app-dialog-main {
        display: flex;
        .image {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .title-main {
            margin: 0 25px;
            border-bottom: 1px solid #dcdfe6;
            position: relative;
            flex: 1;
            .title {
                color: #333;
                font-size: 24px;
                padding-bottom: 12px;
            }
            .info {
                color: #666;
                i {
                    font-size: 14px;
                }
            }
        }
        .btn {
            position: absolute;
            right: 48px;
            top: 54px;
            /deep/ .el-button {
                border-radius: 4px;
            }
            /deep/ .el-button--primary {
                background-color: #1089e9;
                border-color: #1089e9;
            }
        }
    }
    .app-dialog-brief {
        margin-bottom: 24px;
        .title {
            margin-top: 24px;
            font-size: 16px;
            color: #333333;
            margin-bottom: 12px;
            font-weight: bold;
        }
        .brief {
            font-size: 14px;
        }
    }
    .app-dialog-banner {
        border: 1px solid #dcdfe6;
        margin-bottom: 24px;
        /deep/ .el-carousel__container {
            height: 390px;
        }
    }
    .app-dialog-desc {
        font-size: 14px;
        min-height: 100px;
        line-height: 24px;
    }
    /deep/ .el-dialog {
        border-radius: 8px;
        width: 850px !important;
        .el-dialog__header {
            border-bottom: none;
        }
        .el-dialog__header {
            padding: 0;
        }
        .el-dialog__body {
            padding: 24px;
        }
    }
}
@media screen and (max-width: 1280px) {
    .app-dialog {
        /deep/ .el-dialog {
            border-radius: 8px;
            width: 650px !important;
        }
        .app-dialog-main {
            .title-main {
                .title {
                    font-size: 20px;
                    padding-bottom: 12px;
                }
            }
        }
        .app-dialog-brief {
            .title {
                font-size: 14px;
            }
            .brief {
                font-size: 12px;
            }
        }
        .app-dialog-banner {
            /deep/ .el-carousel__container {
                height: 292px;
            }
        }
    }
}
</style>
