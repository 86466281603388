<template>
    <div class="home">
        <router-view />
        <video-dialog :visible.sync="visible" :videoLink="videoSrc"></video-dialog>
        <preview-video :preVisible.sync="preVisible" :videoLink="preVideoSrc"></preview-video>
        <record-toolbar :recordVisible.sync="recordVisible"></record-toolbar>
        <!-- <notify></notify> -->
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import videoDialog from '@/components/videoDialog';
import previewVideo from '@/components/record/previewVideo';
import recordToolbar from '@/components/record/recordToolbar';
//import notify from '@/components/notify';

export default {
    name: 'home',
    data() {
        return {
            visible: false,
            preVisible: false,
            recordVisible: false
        };
    },
    components: {
        videoDialog,
        previewVideo,
        recordToolbar
        //notify
    },
    computed: {
        ...mapState(['videoSrc']),
        ...mapState(['preVideoSrc']),
        ...mapState(['showRecording'])
    },
    watch: {
        videoSrc(val) {
            if (val) {
                this.visible = true;
            }
        },
        visible(val) {
            if (!val) {
                this.setState({ videoSrc: '' });
            }
        },
        preVideoSrc(val) {
            if (val) {
                this.visible = false;
                this.preVisible = true;
            }
        },
        preVisible(val) {
            if (!val) {
                this.setState({ preVideoSrc: '' });
            }
        },
        showRecording(val) {
            if (val) {
                this.recordVisible = true;
            } else {
                this.recordVisible = false;
            }
        }
    },
    methods: {
        ...mapActions(['setState', 'unreadPcTotal'])
    },
    mounted() {
        this.unreadPcTotal();
    }
};
</script>
