<template>
    <div class="message" :style="{ height: `${mainHeight}px` }">
        <div class="mess-main">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane name="all">
                    <span class="mess-label" slot="label">全部消息</span>
                    <component :is="useComp" :status="activeName"></component>
                </el-tab-pane>
                <el-tab-pane name="already">
                    <span class="mess-label" slot="label">已读消息</span>
                    <component :is="useComp" :status="activeName"></component>
                </el-tab-pane>
                <el-tab-pane name="unread">
                    <span class="mess-label" slot="label">未读消息</span>
                    <component :is="useComp" :status="activeName"></component>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import msgEmpty from './empty';
import msgMain from './main';

export default {
    data() {
        return {
            activeName: 'all'
        };
    },
    computed: {
        ...mapGetters(['alreadyMsgList', 'unreadMsgList']),
        ...mapState({
            mainHeight: 'mainHeight',
            msgList: state => state.message.msgList
        }),
        useComp() {
            let data = this.msgList;
            if (this.activeName === 'already') {
                data = this.alreadyMsgList;
            } else if (this.activeName === 'unread') {
                data = this.unreadMsgList;
            }

            return data.length ? 'msgMain' : 'msgEmpty';
        }
    },
    components: {
        msgEmpty,
        msgMain
    },
    methods: {
        ...mapActions(['reqMsgList', 'setState']),
        handleClick() {
            this.setState({
                msgList: this.msgList.map(item => ({ ...item, active: false })),
                msgDetail: null,
                _deep: 'message'
            });
        }
    },
    mounted() {
        let params = { pageNum: 1, ...this.$route.query };

        this.setState({
            msgDetail: null,
            _deep: 'message'
        });
        this.reqMsgList(params);
    }
};
</script>

<style lang="less" scoped>
.message {
    background-color: #fafafa;
    padding: 24px;
    box-sizing: border-box;
    .mess-main {
        box-sizing: border-box;
        padding: 16px 0;
        background-color: #fff;
        height: 100%;
        border-radius: 2px;
    }
    .mess-label {
        padding: 9px 12px;
    }
    /deep/ .el-tabs {
        .el-tabs__header {
            padding: 0 32px;
            margin-bottom: 0;
        }
        .el-tabs__item {
            padding: 0 7px;
        }
        .el-tabs__nav-scroll {
            padding-bottom: 16px;
        }
        .el-tabs__active-bar {
            display: none;
        }
        .is-active {
            .mess-label {
                color: #096dd9;
                background: rgba(9, 109, 217, 0.1);
                border-radius: 4px;
            }
        }
        .el-tabs__nav-wrap::after {
            background-color: #eee;
            height: 1px;
        }
    }
}
</style>
