<template>
    <div class="app-lib-black" v-loading="loading">
        <div class="app-title">{{ title }}</div>
        <div class="app-lib-box" v-if="data.length">
            <div class="app-lib-item" v-for="(item, index) in data" :key="index">
                <div @click="clickEvent(item)">
                    <div class="app-card-img">
                        <img :src="imgBanner(item.banner)" class="image" />
                    </div>

                    <div class="app-card-text">
                        <div class="icon">
                            <img :src="item.icon" />
                        </div>
                        <div class="title">{{ item.name }}</div>
                        <div class="brief">
                            {{ item.description }}
                        </div>
                        <div v-if="item.hasAuthorization === 1">
                            <!-- <el-button
                                size="small"
                                :disabled="item.terminalType !== 1"
                                @click.stop="handleSubscribe(item)"
                                v-if="!item.subscribe"
                                >立即订阅</el-button
                            > -->
                            <el-button
                                size="small"
                                type="primary"
                                :disabled="item.terminalType !== 1"
                                v-if="item.terminalType !== 2"
                                @click.stop="openApp(item)"
                                >打开应用</el-button
                            >
                        </div>
                        <div class="no-auth" v-else>
                            <i class="iconfont" style="vertical-align: bottom">&#xe64b;</i>
                            暂无权限，请联系系统管理员
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty" v-else>应用库为空哦！</div>
        <app-dialog
            :visible.sync="visible"
            :data="dialogData"
            @open="data => openApp(data)"
        ></app-dialog>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import appDialog from './appLibBlackDialog';
import { filterData } from '@/services';
export default {
    name: 'MyAppList',
    data() {
        return {
            visible: false,
            dialogData: {},
            loading: false
        };
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => []
        }
    },
    components: {
        appDialog
    },
    computed: {
        ...mapState(['appInfo'])
    },
    methods: {
        ...mapActions(['setState', 'appSubscribe', 'getApplicationList', 'getUsersAccess']),
        clickEvent(item) {
            this.$router.push(
                `/workCenter/appDetail?id=${item.id}&hasAuthorization=${item.hasAuthorization}`
            );
            // this.visible = true;
            // this.dialogData = item;
        },
        async openApp(item) {
            filterData('app', {
                appUrl: item.hostName,
                appName: item.name,
                appId: item.id,
                menuName: '',
                menuPath: '',
                menuId: ''
            });
            const hostName = item.hostName;

            if (hostName) {
                if (hostName.includes('http://') || hostName.includes('http://')) {
                    window.location.href = hostName;
                } else {
                    this.appInfo[hostName] = item;
                    this.setState({
                        appInfo: this.appInfo
                    });

                    const res = await await this.getUsersAccess({
                        applicationId: item.id,
                        hostName
                    });

                    if (res.resultCode === '0') {
                        //this.$router.push(hostName);

                        this.$router.push(hostName);
                    }
                }
            } else {
                this.$message.error('应用暂时无法使用！');
            }
        },

        handleSubscribe(item) {
            this.loading = true;
            this.appSubscribe({
                id: item.id
            })
                .then(() => {
                    this.loading = false;

                    this.getApplicationList({ pageSize: 1000 }).then(() => {
                        this.$message({
                            message: '成功订阅该应用',
                            type: 'success'
                        });
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        imgBanner(data) {
            if (data) {
                let banners = data.split(',');

                return banners[0];
            } else {
                return '';
            }
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
.app-lib-black {
    width: 1248px;
    margin: 0 auto;
    .app-lib-box {
        display: flex;
        flex-wrap: wrap;
    }
    .app-title {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .app-lib-item {
        background-color: #fff;
        border-radius: 8px;
        margin-right: 16px;
        margin-bottom: 16px;
        width: 298px;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.06), 0px 3px 6px 0px rgba(0, 0, 0, 0.1),
                0px 1px 2px -2px rgba(0, 0, 0, 0.14);
        }
    }
    .app-item-card {
        margin-bottom: 30px;

        cursor: pointer;
    }
    .app-card-img {
        height: 145px;
        text-align: center;
        overflow: hidden;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        img {
            width: 100%;
        }
    }
    .app-card-text {
        padding: 24px;
        /deep/ .el-button {
            width: 88px;
            height: 36px;
            border-radius: 4px;
        }
        /deep/ .el-button--primary {
            background-color: #1089e9;
            border-color: #1089e9;
        }
        .icon {
            img {
                width: 48px;
                height: 48px;
            }
            margin-bottom: 8px;
        }
        .title {
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            margin-bottom: 8px;
        }
        .brief {
            font-size: 14px;
            line-height: 24px;
            height: 48px;
            color: #999;
            margin-bottom: 16px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .no-auth {
            font-size: 14px;
            color: #a3a3a3;
        }
    }
    .empty {
        padding: 20px;
        color: rgb(153, 153, 153);
        text-align: center;
        font-size: 14px;
    }
}
@media screen and (max-width: 1280px) {
    .app-lib-black {
        width: 932px;
        .app-lib-item:nth-child(3n) {
            margin-right: 0;
        }
        // .app-title {
        //     font-size: 14px;
        // }
        // .app-card-img {
        //     height: 135px;
        // }
        // .app-card-text {
        //     padding: 16px;

        //     .title {
        //         font-size: 14px;
        //         margin-bottom: 4px;
        //     }
        //     .brief {
        //         font-size: 12px;
        //         margin-bottom: 10px;
        //         line-height: 18px;
        //         height: 38px;
        //     }
        // }
    }
}
@media screen and (min-width: 1281px) {
    .app-lib-item:nth-child(4n) {
        margin-right: 0;
    }
}
</style>
