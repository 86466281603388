<template>
    <el-container v-loading="loading" class="layout-child">
        <el-header class="head" style="height: 59px">
            <head-logo
                :name="currAppInfo && currAppInfo.name"
                @onClick="visible = true"
            ></head-logo>
            <head-oper
                @loginOut="loginOut"
                @updatePwd="pwdVisible = true"
                @updatePhone="phoneVisible = true"
                @showRecording="showRecording"
            ></head-oper>
        </el-header>
        <component :is="useComp">
            <slot></slot>
        </component>
        <left-drawer :visible.sync="visible">
            <left-menu :data="myAppList"></left-menu>
        </left-drawer>
        <el-dialog
            title="修改登录密码"
            :close-on-click-modal="false"
            :visible.sync="pwdVisible"
            width="358px"
        >
            <mdos-pwd-form />
        </el-dialog>
        <el-dialog
            title="修改手机号"
            :close-on-click-modal="false"
            :visible.sync="phoneVisible"
            width="358px"
        >
            <phone-dialog @closeModal="closeModal" />
        </el-dialog>
    </el-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import MdosPwdForm from '@/components/pwdForm';
import PhoneDialog from '@/components/phoneDialog';
import HeadLogo from '@/components/layout/headLogo';
import HeadOper from '@/components/layout/headOper';
import LeftMenu from '@/components/layout/leftMenu';
import LeftDrawer from '@/components/layout/leftDrawer';
import AuthEmpty from './authEmpty';
import LayoutMain from './main';

export default {
    name: 'Layout',
    data() {
        return {
            loading: false,
            menuType: 0,
            visible: false,
            pwdVisible: false,
            phoneVisible: false
        };
    },
    computed: {
        ...mapState(['myAppList', 'appInfo', 'currAppInfo', 'accessList', 'access']),
        ...mapGetters(['menu']),

        useComp() {
            if (this.isRenderNoAuth) {
                return 'authEmpty';
            }

            return 'layoutMain';
        },

        // 是否渲染没有权限页面
        isRenderNoAuth() {
            return this.menu && !this.menu.length;
        },

        // 获取当前路由
        currPath() {
            const currPath = this.$route.path
                ?.split('/')
                ?.filter(item => item)
                ?.splice(2)
                ?.join('/');

            return currPath ? `/${currPath}` : null;
        },

        // 获取路由前缀
        currHostName() {
            const currPath = this.$route.path
                ?.split('/')
                ?.filter(item => item)
                ?.splice(0, 2)
                ?.join('/');

            return currPath ? `/${currPath}` : null;
        }

        // // 当前应用信息
        // currAppInfo() {
        //     return this.appInfo[this.currHostName];
        // }
    },
    created() {
        const documentHeight = document.body.offsetHeight;

        this.setState({
            mainHeight: documentHeight - 60
        });
    },

    components: {
        HeadLogo,
        HeadOper,
        LeftMenu,
        LeftDrawer,
        MdosPwdForm,
        AuthEmpty,
        LayoutMain,
        PhoneDialog
    },
    methods: {
        ...mapActions([
            'getMyApplicationList',
            'setState',
            'logOut',
            'getAppInfo',
            'getUsersAccess'
        ]),
        loginOut() {
            this.logOut();
        },
        onNavClick(index) {
            this.menuType = index;
        },
        // 从首页进来进行重定向到第一个菜单
        redirect(menuData) {
            let routePath = null;
            if (
                this.$utils.get(menuData, '[0].routePath', null) &&
                this.$utils.get(menuData, '[0].leafFlag') === 1
            ) {
                routePath = this.$utils.get(menuData, '[0].routePath');
            } else if (
                this.$utils.get(menuData, '[0].children[0].routePath', null) &&
                this.$utils.get(menuData, '[0].children[0].leafFlag') === 1
            ) {
                routePath = this.$utils.get(menuData, '[0].children[0].routePath');
            } else if (this.$utils.get(menuData, '[0].children[0].children[0].routePath', null)) {
                routePath = this.$utils.get(menuData, '[0].children[0].children[0].routePath');
            }

            if (routePath) {
                this.$router.replace(this.currAppInfo.hostName + routePath);
            }
        },
        closeModal(val) {
            this.phoneVisible = val;
        },
        //显示录制toolbar
        showRecording() {
            this.setState({
                showRecording: false
            });
            setTimeout(() => {
                this.setState({
                    showRecording: true
                });
            }, 0);
        }
    },
    watch: {
        '$route.path': {
            async handler() {
                this.visible = false;

                if (this.appInfo[this.currHostName]) {
                    this.setState({
                        currAppInfo: this.appInfo[this.currHostName]
                    });
                } else {
                    const res = await this.getAppInfo({
                        hostName: this.currHostName
                    });
                    if (!res) {
                        this.$message.error('应用不存在！', () => this.$router.push('/'));
                    }
                }

                if (this.accessList[this.currHostName]) {
                    this.setState({
                        access: this.accessList[this.currHostName]
                    });
                } else {
                    await this.getUsersAccess({
                        applicationId: this.currAppInfo.id,
                        hostName: this.currHostName
                    });
                }

                if (!this.currPath) {
                    this.redirect(this.menu);
                }

                /*  if (this.appInfo[this.currHostName]) {
                    this.setState({
                        currAppInfo: this.appInfo[this.currHostName]
                    });
                } else {
                    if (this.currPath && this.currHostName) {
                        await this.getAppInfoByUrl({
                            routePath: this.currPath,
                            hostName: this.currHostName
                        });
                    }
                }

                const hostName = this.currAppInfo.hostName;

                if (path === hostName || path === `${hostName}/`) {
                    console.log('------------', this.accessList[this.currHostName]);
                    this.redirect(this.menu);
                } */
            },
            immediate: true
        }
    },

    mounted() {}
};
</script>

<style lang="less">
.layout-child {
    background-color: #f8f9fc;
    .head {
        line-height: 60px;
        font-size: 14px;
        background: url('~@/assets/img/v2/pic_home_LeftBg.png') no-repeat #1089e9;
    }
}
</style>
